import { IOCRResultsData } from 'types/dataModels';
export const MSAL_CONFIG = 'msalConfig';
export const GTMIDS: { [key: string]: string } = {
    QA: 'GTM-NNTFVVV2',
    'UAT-AUTO': 'GTM-PVGMCBNP'
};
export const ALPHABETICAL_PATTERN = 'a-zA-Z';
export const NUMERIC_PATTERN = '0-9';
export const SPECIAL_SYMBOLS = `$%'"_@~!=&\\-\\,.\\]\\[`;
export const HYPHEN = '-';
export const INT_MAX_NUMBER = 2147483647;
export const MAX_SINGLE_DOCUMENT_SIZE_IN_BYTES = 104857600;
export const MAX_ALL_DOCUMENTS_SIZE_IN_BYTES = 209715200;
export const CORRECT_FILE_TYPES_FOR_UPLOAD = [
    'image/tiff',
    'application/pdf',
    'text/html',
    'application/msword',
    'application/rtf',
    'text/plain'
];
export const DEFAULT_FILE_UPLOAD_ERROR =
    'Uploading error. Something went wrong. Try uploading the file again.';

export enum ERROR_TYPES {
    fileUpload = 'fileUpload'
}

export enum WorkbenchTabsEnum {
    searchReport = 'Search Report',
    orderDetails = 'Order Details',
    vestingOwners = 'Vesting',
    legalDescription = 'Legal Description',
    taxes = 'Taxes',
    exceptionsRequirements = 'Exceptions/Requirements'
}

export enum WorkbenchOrderDetailsSubTabsEnum {
    parties = 'Parties',
    generalInfo = 'General Info',
    propertyDetail = 'Property Details'
}

export const WORKBENCH_TABS_NAMES = [
    WorkbenchTabsEnum.searchReport,
    WorkbenchTabsEnum.orderDetails,
    WorkbenchTabsEnum.taxes,
    WorkbenchTabsEnum.vestingOwners,
    WorkbenchTabsEnum.legalDescription,
    WorkbenchTabsEnum.exceptionsRequirements
];

export const WORKBENCH_TABS_NEED_APPROVAL = [
    WorkbenchTabsEnum.searchReport,
    WorkbenchTabsEnum.vestingOwners,
    WorkbenchTabsEnum.legalDescription
];

export enum OrderReviewStateTypes {
    alert,
    question,
    completed,
    bell
}
export enum TestOrderFormTypes {
    pulse = 'Pulse(Standard Order)',
    resware = 'ResWare'
}

export enum ButtonVariants {
    primary = 'primary',
    secondary = 'secondary',
    success = 'success'
}

export enum InputLabelPositions {
    top = 'top',
    left = 'left'
}

export enum DatePickerViews {
    year = 'year',
    month = 'month',
    day = 'day'
}

export enum StatusIconVariants {
    Automation,
    InProgress,
    InProgressPaused,
    Open,
    Completed,
    Canceled,
    OnHold,
    Rush,
    InRemitting,
    RemittingFailed
}

export enum WorkbenchPanelStatusIconVariants {
    notification = 'notification',
    needConfirmation = 'needConfirmation',
    alert = 'alert'
}

export enum SidebarPageNames {
    dashboard = 'Dashboard',
    workbench = 'Workbench',
    orders = 'Orders',
    history = 'History',
    neworder = 'New Order',
    messages = 'Messages',
    support = 'Support',
    config = 'Configuration'
}

export enum OrdersListTableHeaders {
    selectOrder = 'SelectOrder',
    orderNumber = 'Order №',
    status = 'Order Status',
    action = 'Action',
    stateCounty = 'State-County',
    sla = 'SLA',
    businessSegment = 'Business Segment',
    product = 'Product',
    agent = 'Agent',
    assignedTo = 'Assigned to',
    parcelNumber = 'APN/ Parcel №',
    owners = 'Owners'
}

export enum UploadDocParentPages {
    taxes = 'taxes',
    tsri = 'tsri',
    searchPackage = 'searchPackage',
    startersAndPriors = 'startersAndPriors'
}

export enum DocumentTypes {
    tax = 'Tax',
    assessor = 'Assessor',
    recorded = 'Recorded',
    maps = 'Maps',
    other = 'Other',
    searchPackage = 'Search Package'
}

export enum IconSizes {
    small = 'small',
    large = 'large'
}

export enum ExceptionsRequirementsPanelTypes {
    Requirements = 1,
    Exceptions = 0,
    SpeedCode = 2,
    RequirementSatements = 3
}

export enum DocumentModalErrorMessages {
    incorrectFileSize = 'File size too large. Size cannot exceed 100MB.',
    incorrectFileFormat = 'Invalid File Type. Allowed file types are: TIFF, PDF, HTML, DOC, RTF, TXT',
    incorrectFileFormatAndSize = `Invalid File Type. Allowed file types are: TIFF, PDF, HTML, DOC, RTF, TXT
        and File size too large. Size cannot exceed 100MB.`,
    withoutError = ''
}

/**
 * Document untagging warning messages
 */
export enum KeyDocumentUnTaggingModalWarningMessages {
    vesting = 'Document is the approved source for vesting.',
    legalDescription = 'Document is the approved source for legal.',
    vestingNLegalDescription = 'Document is the approved source for vesting and legal.',

    allDocsOfGroup = 'All documents in group will be untagged.',
    allDocsInclusiveVestingNLegal = 'All documents in group will be untagged. This group includes approved source for vesting and legal.',
    allDocsInclusiveVesting = 'All documents in group will be untagged. This group includes approved source for vesting.',
    allDocsInclusiveLegal = 'All documents in group will be untagged. This group includes approved source for legal.',

    allChildDocsInclusiveVestingNLegal = 'All child documents will be untagged. These documents includes approved source for vesting and legal.',
    allChildDocsInclusiveVesting = 'All child documents will be untagged. These documents includes approved source for vesting.',
    allChildDocsInclusiveLegal = 'All child documents will be untagged. These documents includes approved source for legal.'
}

/**
 * Workbench sidebar panels names
 */
export enum WorkbenchSidebarSubpanelsNames {
    searchCopyOptions = 'Search / Copy options / Customer requirements',
    manualSearch = 'Manual Search',
    orderHistory = 'Order History',
    keyDocumentsGrouping = 'Key Documents Grouping',
    searchPackage = 'Search Package',
    warnings = 'Warnings',
    startersAndPriors = 'Starter Files'
}

/**
 * Document categories
 */
export enum DocumentCategories {
    CORPORATION = 'CORPORATION',
    RELEASE = 'RELEASE',
    PATRIOTS = 'PATRIOTS',
    STATEBAR = 'STATEBAR',
    CCER = 'CCER',
    MODIFICATION = 'MODIFICATION',
    MASTERDEEDANDBYLAWS = 'MASTERDEEDANDBYLAWS',
    ASSIGNMENT = 'ASSIGNMENT',
    OTHERFILE = 'OTHERFILE',
    WHITEPAGES = 'WHITEPAGES',
    ASSUMPTION = 'ASSUMPTION',
    LIEN = 'LIEN',
    MORTGAGE = 'MORTGAGE',
    APPOINTMENT = 'APPOINTMENT',
    DRIVERDATA = 'DRIVERDATA',
    SUBSTITUTION = 'SUBSTITUTION',
    PRIORFILE = 'PRIORFILE',
    MASTERDEED = 'MASTERDEED',
    CITYTAX = 'CITYTAX',
    SUBORDINATION = 'SUBORDINATION',
    RECORDMAP = 'RECORDMAP',
    HOA = 'HOA',
    PARCELMAP = 'PARCELMAP',
    EASEMENT = 'EASEMENT',
    AFFIDAVIT = 'AFFIDAVIT',
    TRANSFER = 'TRANSFER',
    COURT = 'COURT',
    LEXISNEXIS = 'LEXISNEXIS',
    ASSESSOR = 'ASSESSOR',
    COMPLAINT = 'COMPLAINT',
    COUNTYTAX = 'COUNTYTAX',
    RQNOTICE = 'RQNOTICE',
    TRACTMAP = 'TRACTMAP',
    RESTRICTION = 'RESTRICTION',
    LEASE = 'LEASE',
    BYLAWS = 'BYLAWS',
    PLAT = 'PLAT',
    MISCELLANEOUS = 'MISCELLANEOUS'
}

export const whitelistedKeyDocumentCategories: Array<keyof typeof DocumentCategories> = [
    DocumentCategories.HOA,
    DocumentCategories.DRIVERDATA,
    DocumentCategories.LEXISNEXIS
];

export enum PartyRoles {
    contractor = 'CONTRACTOR',
    grantee = 'GRANTEE',
    grantor = 'GRANTOR',
    lender = 'LENDER',
    nominee = 'NOMINEE',
    simple = 'SIMPLE',
    trustee = 'TRUSTEE',
    none = 'NONE'
}

export const granteeParties = [
    { label: 'Grantees Lender', role: PartyRoles.lender },
    { label: 'Grantees Lender Nominee', role: PartyRoles.nominee },
    { label: 'Grantees Trustee', role: PartyRoles.trustee }
];

export enum DroppableSections {
    Exceptions = 'exceptions',
    Requirements = 'requirements',
    Codebook = 'codebook',
    TaxCodes = 'taxCodes',
    Legal = 'legal',
    Vesting = 'vesting',
    FullScreenLegal = 'fullScreenLegal',
    FullScreenVesting = 'fullScreenVesting',
    UploadDocModal = 'uploadDocModal',
    UploadTaxForm = 'UploadTaxForm',
    UploadKeyDocumentForm = 'UploadKeyDocumentForm',
    FullScreenDocumentRows = 'fullScreenDocumentRows',
    RequirementsCode = 'requirementsCode',
    ExceptionsCode = 'exceptionsCode',
    InstrumentCard = 'instrumentCard',
    RequirementsStatments = 'requirementsStatments',
    ExceptionsStatments = 'exceptionsStatments'
}

export enum SnackbarCloseReason {
    Timeout = 'timeout',
    AutoHideDuration = 'autoHideDuration',
    Clickaway = 'clickaway',
    EscapeKeyDown = 'escapeKeyDown'
}

export enum SnackbarSeverity {
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
    Success = 'success'
}

export enum SnackbarSuccessMessage {
    AddDocumentSuccess = 'Your document has been created successfully',
    UpdateDocumentSuccess = 'Your document has been updated successfully',
    OCRStartedOnUpdatedDoc = 'The Document was updated successfully. OCR has started',
    OCRStartedOnCompleteDoc = 'The Document was created successfully. OCR has started'
}

export enum AlertVariant {
    Filled = 'filled',
    Outlined = 'outlined',
    Standard = 'standard'
}

export enum DocumentGroup {
    keyDocument,
    searchPackage,
    startersAndPriors
}

export enum OrdersListTableSorting {
    orderNumber = 'ProjectOrder.FileNumber',
    status = 'ProjectStatusId',
    sla = 'TimeLeft.Hours',
    action = 'Action',
    stateCounty = 'OrderId',
    product = 'ProductTypeId',
    businessSegment = 'ProductType.BusinessSegment.Id',
    assignedTo = 'AssignedTo.DisplayName'
}

export enum TaxCategories {
    CityTax = 'CITYTAX',
    CountyTax = 'COUNTYTAX'
}

export enum TaxInstallmentStatuses {
    open = 'Open',
    paid = 'Paid',
    delinquent = 'Delinquent'
}

export enum GlobalStatusFilter {
    CompletedExcluded = 'Completed excluded',
    OnlyCompleted = 'Only completed',
    AllOrders = 'All statuses'
}

export const OrderActions = [
    { id: 0, name: '-' },
    { id: 1, name: 'Examining' },
    { id: 2, name: 'Running Auto Search' }
];

export enum GlobalOrderFilter {
    AssignedToMe = 'Assigned to me',
    Unassigned = 'Unassigned',
    AllOrders = 'All orders'
}

export enum OrderStatusesIds {
    New = '7d31510a-ce9f-4c86-9443-aa593a197cf0',
    Rejected = '9e703f2b-d9f4-4038-a3dc-0b099de02a82',
    WaitForActivate = '031555b1-b0dd-46e4-b502-4f7c67ba3620',
    Searching = '0a00c3f0-bb9e-41dd-bcdf-88d8e690f678',
    Open = 'ed135ec9-b263-48ac-83f2-719a2170f1b9',
    InProgress = '02a50073-6f22-4e37-ab77-dc0c25904ad6',
    SearchCompleted = '210d8fee-6e8b-4a78-a296-3d277b5e9827',
    Completed = 'fd636b59-a009-49d5-9be6-4c93d1bb8e9b',
    Canceled = '8183c613-3929-4a89-a04f-5e10512bbd40',
    Active = 'ec9ea30e-544d-4274-81d1-6e228a379939',
    OnHold = 'c32716ec-e5fa-4c30-97e3-9eaa1e2e7e6a',
    ReassignedToVendor = '437654a1-e7c8-4089-bec0-c9cd913c3085',
    InProgressPaused = '48bacf11-768d-4b01-a1ab-a1d02cb5025a',
    InRemitting = 'd00ef9af-4b95-4d38-8468-8c843e46c07b',
    RemittingFailed = 'e384b2d3-596b-4fa7-a2ea-deb30e6f2700'
}
export const COMPLETED_EXCLUDED_FILTERS = [
    OrderStatusesIds.New,
    OrderStatusesIds.Rejected,
    OrderStatusesIds.WaitForActivate,
    OrderStatusesIds.Searching,
    OrderStatusesIds.Open,
    OrderStatusesIds.InProgress,
    OrderStatusesIds.SearchCompleted,
    OrderStatusesIds.Canceled,
    OrderStatusesIds.Active,
    OrderStatusesIds.OnHold,
    OrderStatusesIds.ReassignedToVendor,
    OrderStatusesIds.InProgressPaused,
    OrderStatusesIds.InRemitting,
    OrderStatusesIds.RemittingFailed
];

export const COMPLETED_FILTERS = [OrderStatusesIds.Completed];

export const statusIconsMapping = {
    [OrderStatusesIds.Rejected]: StatusIconVariants.Automation,
    [OrderStatusesIds.SearchCompleted]: StatusIconVariants.Automation,
    [OrderStatusesIds.Completed]: StatusIconVariants.Completed,
    [OrderStatusesIds.WaitForActivate]: StatusIconVariants.Automation,
    [OrderStatusesIds.Canceled]: StatusIconVariants.Canceled,
    [OrderStatusesIds.Active]: StatusIconVariants.InProgress,
    [OrderStatusesIds.Open]: StatusIconVariants.Open,
    [OrderStatusesIds.Searching]: StatusIconVariants.Automation,
    [OrderStatusesIds.OnHold]: StatusIconVariants.OnHold,
    [OrderStatusesIds.New]: StatusIconVariants.Automation,
    [OrderStatusesIds.ReassignedToVendor]: StatusIconVariants.OnHold,
    [OrderStatusesIds.InProgress]: StatusIconVariants.InProgress,
    [OrderStatusesIds.InProgressPaused]: StatusIconVariants.InProgressPaused,
    [OrderStatusesIds.InRemitting]: StatusIconVariants.InRemitting,
    [OrderStatusesIds.RemittingFailed]: StatusIconVariants.RemittingFailed
};

export const globalStatusFilterMapping = {
    [GlobalStatusFilter.CompletedExcluded]: COMPLETED_EXCLUDED_FILTERS,
    [GlobalStatusFilter.OnlyCompleted]: COMPLETED_FILTERS,
    [GlobalStatusFilter.AllOrders]: [] as string[]
};

export const defaultAlertState = {
    isAlertOpen: false,
    headerText: '',
    bodyText: ''
};

export enum ReferenceInputNames {
    Book = 'book',
    Page = 'page',
    InstrumentNumber = 'instrumentNumber',
    InstrumentYear = 'instrumentYear',
    Year = 'year'
}

export enum AmountTypes {
    Amount = 'hasAmountField',
    TransferTaxAmount = 'hasTransferTaxAmountField',
    AssessorAmount = 'hasAssessorAmountFields',
    TaxAmount = 'hasTaxAmountFields'
}

export enum TextSizeOptions {
    UPPER = 'upper',
    TITLE = 'title',
    LOWER = 'lower'
}

export enum FieldValueTypes {
    Boolean = 0,
    Object = 1,
    Int32 = 10,
    Int64 = 11,
    Double = 12,
    Decimal = 13,
    DateTime = 20,
    DateTimeOffset = 21,
    String = 30,
    DocumentParty = 100,
    DocumentInstallment = 101
}

export enum ConfigManagerFieldTypes {
    String = 0,
    LongString = 1,
    Boolean = 2,
    Int64 = 3,
    Number = 4,
    Decimal = 5,
    Date = 6,
    Time = 7,
    DateTime = 8,
    GuidWOptions = 9,
    GuidWURI = 10,
    ForeignKey = 11,
    JSONObject = 12,
    Template = 13
}

/**
 * Status	                 |   Description
 *  Created	                 |   Indicates the request has been received from the user.
 *  Processing	             |   Shows that the document has been forwarded to ATS for processing.
 *  OCR_complete	         |   Indicates that the OCRed document has been received from ATS.
 *  Conversion_complete      |   Shows that data has been successfully mapped from OCR to SO.
 *  Completed	             |   Currently not used
 *  Cancelled	             |   Occurs when a user sends a cancellation request.
 *  RequestMessageFailed	 |   Occurs when Pulse BE fails to send the document to ATS via the service bus.
 *  ResultProcessFailed	     |   Occurs when Pulse BE fails to extract the OCRed zip file from the ATS service bus.
 *  ResultConversionFailed   |  Occurs when Pulse BE fails to map the DIP result into SO.
 */
export enum AllowedStatusForOCRRequest {
    created = 'Created',
    processing = 'Processing',
    ocrComplete = 'OCR_complete',
    conversionComplete = 'Conversion_complete',
    // completed = 'Completed',
    cancelled = 'Cancelled',
    requestMessageFailed = 'RequestMessageFailed',
    resultProcessFailed = 'ResultProcessFailed',
    resultConversionFailed = 'ResultConversionFailed'
}

export const EnableOCRSuccessStatus = [
    AllowedStatusForOCRRequest.ocrComplete,
    AllowedStatusForOCRRequest.resultConversionFailed,
    AllowedStatusForOCRRequest.resultProcessFailed
    // AllowedStatusForOCRRequest.requestMessageFailed // So far haven't dealt with this one
];

export enum OCRStartStatuses {
    completed = 'COMPLETED',
    processing = 'PROCESSING',
    failed = 'FAILED',
    idle = 'IDLE',
    initiated = 'INIT'
}

export enum InputTypes {
    Date = 'DATE',
    Text = 'TEXT',
    Amount = 'AMOUNT',
    Vesting = 'VESTING',
    Year = 'YEAR'
}

export const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';

export const DEFAULT_REQUIRED_FIELD_MESSAGE = 'Required Field';

export const ORDER_STATUSES = ['Check in', 'On Hold', 'Pause'];

export enum ProjectOrderStatus {
    onHold = 'On Hold',
    InProgressPaused = 'In progress - paused',
    checkIn = 'Check in',
    pause = 'Pause'
}

/**
 * MAx length input field validation for Book | Page | Instrument No | Parcel No
 */
export const maxLength = 30;

export const codeBookIconLabel = 'Show/Hide Codebook';

export const BUTTON_TEXT_LENGTH = 22;

export const DEFAULT_INTEREST_ESTATE_TYPE = 'FEE SIMPLE';

export enum Modes {
    ADD = 'add',
    UPDATE = 'update'
}

export const profilePictureSupportedFormats = ['jpg', 'jpeg', 'png'];

export enum RunsheetTableHeaders {
    all = 'All',
    detailsImage = '',
    recordedDate = 'Recorded Date',
    amount = 'Amount',
    instrumentType = 'Instr Type',
    instrument = 'Instrument',
    bookPage = 'Book/Page',
    grantor = 'Grantor',
    grantee = 'Grantee',
    remarks = 'Remarks'
}

export const JWT_TOKEN_ROLE_KEY = 'extension_pulse_roles';

export enum Roles {
    PulseAdmin = 'Pulse.Admin',
    PulseManager = 'Pulse.Manager',
    PulseExaminer = 'Pulse.Examiner',
    PulseExamReviewer = 'Pulse.ExamReviewer'
}

export enum PriorProlicyGeneralInformationTabs {
    PropertyDescription = 'Property Description',
    Statements = 'Statements'
}

export const RoleMappings = {
    'Pulse.Admin': 'Admin',
    'Pulse.Manager': 'Manager',
    'Pulse.Examiner': 'Examiner',
    'Pulse.ExamReviewer': 'Exam Reviewer'
};

export const emptyOCRData: IOCRResultsData = {
    OCR: [],
    Items: [],
    Pages: [],
    relativeCoordinates: [],
    Aliases: [],
    currentOcrPageIndex: 1
};
export enum AssignedTo {
    Assigned = 'A',
    Unassigned = 'U'
}

export const RUNSHEET = 'RUNSHEET';

export enum AssignExaminerTableHeader {
    member = 'Member',
    role = 'Role',
    action = 'Action',
    ordersAssigned = 'Orders assigned',
    sla = 'SLA',
    stateCounties = 'State - Counties',
    actionMenu = 'Action Menu'
}

export const AssignExaminerTableHeaderList = [
    AssignExaminerTableHeader.member,
    AssignExaminerTableHeader.role,
    AssignExaminerTableHeader.action,
    AssignExaminerTableHeader.ordersAssigned,
    AssignExaminerTableHeader.sla,
    AssignExaminerTableHeader.stateCounties,
    AssignExaminerTableHeader.actionMenu
];

export const AssignExaminerSortDirections: Directions = {
    [AssignExaminerTableHeader.member]: 'desc',
    [AssignExaminerTableHeader.role]: 'desc',
    [AssignExaminerTableHeader.action]: 'desc',
    [AssignExaminerTableHeader.ordersAssigned]: 'desc',
    [AssignExaminerTableHeader.sla]: 'asc',
    [AssignExaminerTableHeader.stateCounties]: 'desc',
    [AssignExaminerTableHeader.actionMenu]: 'desc'
};

export interface Directions {
    [AssignExaminerTableHeader.member]?: 'asc' | 'desc';
    [AssignExaminerTableHeader.role]?: 'asc' | 'desc';
    [AssignExaminerTableHeader.action]?: 'asc' | 'desc';
    [AssignExaminerTableHeader.ordersAssigned]?: 'asc' | 'desc';
    [AssignExaminerTableHeader.sla]?: 'asc' | 'desc';
    [AssignExaminerTableHeader.stateCounties]?: 'asc' | 'desc';
    [AssignExaminerTableHeader.actionMenu]?: 'asc' | 'desc';
}
