import { Grid } from '@mui/material';
import tinymce from 'tinymce';
import {
    DocumentGroup,
    DroppableSections,
    ExceptionsRequirementsPanelTypes,
    ProjectOrderStatus,
    WorkbenchTabsEnum,
    emptyOCRData
} from 'core/constants/common';
import {
    fetchExamOrder,
    fetchProjectOrder,
    setCurrentExamOrder
} from 'core/features/currentExamOrder/currentExamOrderSlice';
import { fetchDocumentTypes } from 'core/features/documentTypes/documentTypesSlice';
import { setDragging } from 'core/features/dragDrop/dragDropSlice';
import { fetchExamCodeBookTemplatesData } from 'core/features/examCodeBook/examCodeBookSlice';
import {
    fetchFinalReviewDataThunk,
    fetchFinalReviewWarningsThunk
} from 'core/features/examOrderFinalReview/examOrderFinalReviewSlice';
import { fetchExamOrderParentSuccessorsDocumentGroupData } from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import { fetchExamOrderLegalDescriptionData } from 'core/features/examOrderLegalDescription/examOrderLegalDescriptionSlice';
import { getExamOrderSearchPackageGroupDataThunk } from 'core/features/examOrderSearchPackageGroup/examOrderSearchPackageGroupSlice';
import { fetchExamOrderSearchReportData } from 'core/features/examOrderSearchReport/examOrderSearchReportSlice';
import { fetchExamOrderStartersAndPriorsGroupDataThunk } from 'core/features/examOrderStartersAndPriorsGroup/examOrderStartersAndPriorsGroupSlice';
import { fetchExamOrderTaxesDataThunk } from 'core/features/examOrderTaxes/examOrderTaxesSlice';
import { fetchExamOrderVestingData } from 'core/features/examOrderVesting/examOrderVestingSlice';
import {
    setIsKeyDocsExpanded,
    setIsManualSearchExpanded,
    setIsOrderHistoryExpanded,
    setIsPinnedWarningsExpanded,
    setIsSCCExpanded,
    setIsSearchPackageExpanded,
    setOpenKeyDocsGroup,
    setOpenSearchPkgGroup
} from 'core/features/fullScreenSidebar/fullScreenSidebarSlice';
import {
    ISetKeyDocumentGroupPayloadAction,
    setKeyDocumentsGroup
} from 'core/features/keyDocumentsGrouping/keyDocumentsGroupingSlice';
import { fetchOrderHistory } from 'core/features/orderHistory/orderHistorySlice';
import {
    ISetSearchPackageGroupPayloadAction,
    setSearchPackageGroup
} from 'core/features/searchPackageGrouping/searchPackageGroupingSlice';
import { setWarningsPanelPinnedState } from 'core/features/searchWarnings/searchWarningsSlice';
import {
    fetchAllowedStatuses,
    setForceEditable,
    setReadOnlyView
} from 'core/features/workbenchStatus/workbenchStatusSlice';
import {
    fetchExamOrderReviewStateThunk,
    resetWorkbenchTabs,
    setIsFinalReviewVisible
} from 'core/features/workbenchTabs/workbenchTabsSlice';
import {
    setIsFullScreenMode,
    setIsOpen,
    setIsPopoutOpen
} from 'core/features/workenchSidebar/workbenchSidebarSlice';
import { setOcrObjectData } from 'core/features/ocrData/ocrDataSlice';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { useAppSelector } from 'core/hooks/useAppSelector';
import { isEmpty } from 'lodash';
import EmptyOrder from 'pages/Workbench/EmptyOrder';
import { useEffect, useRef } from 'react';
import {
    BeforeCapture,
    DragDropContext,
    DragStart,
    DropResult,
    Position
} from 'react-beautiful-dnd';

import InlineLoadingSpinner from 'components/common/InlineLoadingSpinner';
import {
    ISetStartersAndPriorsGroupPayloadAction,
    setStartersAndPriorsGroup
} from 'core/features/startersAndPriorsGrouping/startersAndPriorsGroupingSlice';
import { addOrderDocumentCodeThunk } from 'core/features/examOrderKeyDocumentGroup/examOrderKeyDocumentGroupSlice';
import { addOrderSearchPackageDocumentCodeThunk } from 'core/features/examOrderSearchPackageGroup/examOrderSearchPackageGroupSlice';
import {
    dragCodeToTaxThunk,
    setOrderTaxDocumentCodeThunk
} from 'core/features/examOrderTaxes/examOrderTaxesSlice';
import {
    addFromCodebookThunk,
    getAllExceptionsAndRequirementsThunk,
    reorderColumnThunk,
    setTargetDropPanel
} from 'core/features/exceptions/exceptionsSlice';
import { setLegalDescriptionEditorValue } from 'core/features/legalDescriptionEditor/legalDescriptionEditorSlice';
import {
    setFormData as setKeyDocumentFormData,
    setIsOpen as setKeyDocumentFormOpen
} from 'core/features/uploadKeyDocumentForm/uploadKeyDocumentFormSlice';
import { setIsOpen as setSearchPackageFormOpen } from 'core/features/uploadSearchPackageForm/uploadSearchPackageFormSlice';
import { setIsOpen as setStartersAndPriorsFormOpen } from 'core/features/uploadStartersAndPriorsForm/uploadStartersAndPriorsFormSlice';
import { setFormData as setTaxFormData } from 'core/features/uploadTaxForm/uploadTaxFormSlice';
import { setVestingOwnersEditorValue } from 'core/features/vestingOwnersEditor/vestingOwnersEditorSlice';
import {
    setManualSearchSiteData,
    setManualSearchSiteTypeData,
    setSelectedProperty,
    setDataSourceSelection,
    setDataSourceTypeSelection,
    setManualSearchResults
} from 'core/features/manualSearch/manualSearchSlice';
import { lazyRetry } from 'core/helpers/lazyRetry';
import { useSpecialClientAction } from 'core/hooks/useSpecialClientAction';
import { Suspense, lazy } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { CodeTemplate } from 'types/codebook';
import { DocumentCode } from 'types/dataModels';
import { Paragraph } from 'types/exceptionsRequirements';
import styles from './styles';

export interface WorkbenchLayoutProps {
    forceEditable?: boolean;
}

const UploadKeyDocumentForm = lazy(() =>
    lazyRetry(
        () =>
            import(
                /* webpackChunkName: "UploadKeyDocumentForm" */ 'components/common/UploadKeyDocumentForm'
            ),
        'UploadKeyDocumentForm'
    )
);
const UploadSearchPackageForm = lazy(() =>
    lazyRetry(
        () =>
            import(
                /* webpackChunkName: "UploadSearchPackageForm" */ 'components/common/UploadSearchPackageForm'
            ),
        'UploadSearchPackageForm'
    )
);
const UploadStartersAndPriorsForm = lazy(() =>
    lazyRetry(
        () =>
            import(
                /* webpackChunkName: "UploadStartersAndPriorsForm" */ 'components/common/UploadStartersAndPriorsForm'
            ),
        'UploadStartersAndPriorsForm'
    )
);

/*
 * Render exam order workbench
 * @constructor
 */
const WorkbenchLayout = ({ forceEditable }: WorkbenchLayoutProps) => {
    const params = useParams();
    const { layout } = styles().classes;
    const dispatch = useAppDispatch();
    const { isFullScreen: isFullScreenSidebar } = useAppSelector(
        (state) => state.workbenchSidebarData
    );
    const currentOrder = useAppSelector(
        (state) => state.currentExamOrderData.currentExamOrder
    );
    const isReadyForFinalReview = useAppSelector(
        (state) => state.workbenchTabsData.isReadyForFinalReview
    );
    const activeTab = useAppSelector((state) => state.workbenchTabsData.activeTab);
    const isCodeBookOpen = useAppSelector(
        (state) => state.workbenchSidebarData.isCodeBookOpen
    );

    // Move these to the individual editor's slice
    const { isLegalFullScreen } = useAppSelector((state) => state.legalEditorExtrasData);
    const { isVestingFullScreen } = useAppSelector((state) => state.vestingEditorExtrasData);
    const { formData: keyDocumentFormData, isOpen: isUploadKeyDocumentFormOpen } =
        useAppSelector((state) => state.uploadKeyDocumentFormData);
    const { isOpen: isUploadSearchPackageFormOpen } = useAppSelector(
        (state) => state.uploadSearchPackageFormData
    );
    const { isOpen: isUploadStartersAndPriorsFormOpen } = useAppSelector(
        (state) => state.uploadStartersAndPriorsFormData
    );
    const { formData: taxFormData, isOpen: isUploadTaxFormOpen } = useAppSelector(
        (state) => state.uploadTaxFormData
    );

    const { codeTemplates } = useAppSelector((state) => state.examCodeBookData);
    const paragraphsList = useAppSelector((state) => state.exceptionsData.paragraphsList);
    const { examOrderTaxes } = useAppSelector((state) => state.examOrderTaxesData);
    const { id: orderId } =
        useAppSelector((state) => state.currentExamOrderData.currentExamOrder) || {};
    const presentLegal = useAppSelector(
        (state) => state.legalDescriptionEditorData.present.textEditorValue
    );
    const presentVesting = useAppSelector(
        (state) => state.vestingOwnersEditorData.present.textEditorValue
    );
    const clientSelectionRef = useRef<Position>({ x: 0, y: 0 });

    // to fetch final review, on order details page, need order owner and order status data
    const { projectOrder } = useAppSelector((state) => state.currentExamOrderData);
    const loggedInUser = useAppSelector((state) => state?.profileData?.profile);
    const otherExaminer = loggedInUser.id !== projectOrder?.assignedTo?.id;
    const orderStatus = useAppSelector((state) => state?.workbenchStatusData?.status);
    const isOrderPausedOrHold =
        (otherExaminer && orderStatus === ProjectOrderStatus.InProgressPaused) ||
        orderStatus === ProjectOrderStatus.onHold;

    const updateDocumentsList = () => {
        if (params.orderId !== 'empty' && params.orderId) {
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(params.orderId));
            dispatch(fetchExamOrderStartersAndPriorsGroupDataThunk(params.orderId));
            dispatch(getExamOrderSearchPackageGroupDataThunk(params.orderId));
        }
    };

    const updateKeyDocumentsGroupingList = () => {
        if (params.orderId !== 'empty' && params.orderId) {
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(params.orderId));
        }
    };

    const updateExceptionsRequirements = () => {
        if (params.orderId !== 'empty' && params.orderId) {
            dispatch(getAllExceptionsAndRequirementsThunk(params.orderId));
        }
    };

    const updateSearchPackageList = () => {
        if (params.orderId !== 'empty' && params.orderId) {
            dispatch(getExamOrderSearchPackageGroupDataThunk(params.orderId));
        }
    };

    useEffect(() => {
        function handleMouseMove(event: MouseEvent) {
            const current: Position = {
                x: event.clientX,
                y: event.clientY
            };
            clientSelectionRef.current = current;
        }
        tinymce._setBaseUrl(process.env.PUBLIC_URL + '/tinymce/');
        window.addEventListener('mousemove', handleMouseMove, { passive: true });
        document.addEventListener('updateDocumentList', updateDocumentsList);
        document.addEventListener(
            'updateKeyDocumentsGroupingList',
            updateKeyDocumentsGroupingList
        );
        document.addEventListener(
            'updateExceptionsRequirements',
            updateExceptionsRequirements
        );
        document.addEventListener('updateSearchPackageList', updateSearchPackageList);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('updateDocumentList', updateDocumentsList);
            document.removeEventListener(
                'updateKeyDocumentsGroupingList',
                updateKeyDocumentsGroupingList
            );
            document.addEventListener(
                'updateExceptionsRequirements',
                updateExceptionsRequirements
            );
            document.removeEventListener('updateSearchPackageList', updateSearchPackageList);
        };
    }, []);

    /**
     * Fetch initial data
     * and set to default after unmount
     */
    useEffect(() => {
        if (params.orderId !== 'empty') {
            dispatch(fetchExamOrder(params.orderId));
            dispatch(fetchProjectOrder(params.orderId));
            dispatch(setForceEditable(forceEditable));
            dispatch(fetchExamOrderParentSuccessorsDocumentGroupData(params.orderId));
            dispatch(getExamOrderSearchPackageGroupDataThunk(params.orderId));
            dispatch(fetchExamOrderStartersAndPriorsGroupDataThunk(params.orderId));
            dispatch(fetchOrderHistory(params.orderId));
            dispatch(fetchDocumentTypes());
            dispatch(fetchExamCodeBookTemplatesData(params.orderId));
            dispatch(fetchAllowedStatuses(params.orderId));
            dispatch(fetchExamOrderReviewStateThunk(params.orderId));
            dispatch(fetchExamOrderSearchReportData(params.orderId));
            dispatch(fetchExamOrderTaxesDataThunk(params.orderId));
            dispatch(fetchExamOrderLegalDescriptionData(params.orderId));
            dispatch(fetchExamOrderVestingData(params.orderId));
        }
        return () => {
            dispatch(setKeyDocumentsGroup({} as ISetKeyDocumentGroupPayloadAction));
            dispatch(setSearchPackageGroup({} as ISetSearchPackageGroupPayloadAction));
            dispatch(setStartersAndPriorsGroup({} as ISetStartersAndPriorsGroupPayloadAction));
            dispatch(resetWorkbenchTabs());
            dispatch(setCurrentExamOrder(null));
            dispatch(setIsFullScreenMode(false));
            dispatch(setIsOpen(false));
            dispatch(setIsPopoutOpen(false));
            dispatch(setIsFinalReviewVisible(false));
            dispatch(setReadOnlyView(false));
            dispatch(setIsPinnedWarningsExpanded(false));
            dispatch(setIsSCCExpanded(false));
            dispatch(setIsOrderHistoryExpanded(false));
            dispatch(setIsManualSearchExpanded(false));
            dispatch(setIsKeyDocsExpanded(false));
            dispatch(setIsSearchPackageExpanded(false));
            dispatch(setOpenKeyDocsGroup([]));
            dispatch(setOpenSearchPkgGroup([]));
            dispatch(setWarningsPanelPinnedState(false));
            // clear manual search data
            dispatch(setManualSearchSiteData(null));
            dispatch(setManualSearchSiteTypeData(null));
            dispatch(setSelectedProperty(null));
            dispatch(setDataSourceSelection(null));
            dispatch(setDataSourceTypeSelection(null));
            dispatch(setManualSearchResults(null));
            dispatch(setOcrObjectData(emptyOCRData));
            // close popout window when examiner leaves current workbench order.
            if (window.pulse.popoutWindow) {
                window.pulse.popoutWindow.close();
            }
        };
    }, []);

    useSpecialClientAction();

    /**
     * If all sections are reviewed fetch final review
     * OR If order is on hold or paused, then to view order details data, fetch final review
     */
    useEffect(() => {
        if (params.orderId === 'empty') return;
        if (isReadyForFinalReview || isOrderPausedOrHold) {
            dispatch(fetchFinalReviewDataThunk(params.orderId));
            dispatch(fetchFinalReviewWarningsThunk(params.orderId));
        }
    }, [isReadyForFinalReview, isOrderPausedOrHold]);

    /**
     * Show empty view if current order is empty
     */
    if (isEmpty(currentOrder) || params.orderId === 'empty') {
        return (
            <Grid container className={layout}>
                <EmptyOrder />
            </Grid>
        );
    }

    function codeBeforeCapture(before: BeforeCapture) {
        window.dispatchEvent(
            new CustomEvent('onBeforeCapture', {
                detail: { before, clientSelection: clientSelectionRef.current }
            })
        );
    }

    const codeDragStart = () => {
        if (window.navigator.vibrate) {
            window.navigator.vibrate(100);
        }
    };

    // If drag comes from the codebook, lets now check if where we are trying to drop it
    // The 'Section' variable on codebook templates corelates to whether it is an exc/req
    const handleDragStart = (initial: DragStart) => {
        dispatch(setDragging(true));
        if (initial.source.droppableId === DroppableSections.Codebook) {
            if (isCodeBookOpen && activeTab == WorkbenchTabsEnum.legalDescription) {
                if (isLegalFullScreen) {
                    dispatch(setTargetDropPanel(DroppableSections.FullScreenLegal));
                } else {
                    dispatch(setTargetDropPanel(DroppableSections.Legal));
                }
            } else if (isCodeBookOpen && activeTab === WorkbenchTabsEnum.vestingOwners) {
                if (isVestingFullScreen) {
                    dispatch(setTargetDropPanel(DroppableSections.FullScreenVesting));
                } else {
                    dispatch(setTargetDropPanel(DroppableSections.Vesting));
                }
            } else if (isCodeBookOpen && isFullScreenSidebar) {
                if (isUploadKeyDocumentFormOpen) {
                    dispatch(setTargetDropPanel(DroppableSections.UploadKeyDocumentForm));
                } else if (isUploadTaxFormOpen) {
                    dispatch(setTargetDropPanel(DroppableSections.UploadTaxForm));
                } else {
                    dispatch(setTargetDropPanel(DroppableSections.FullScreenDocumentRows));
                }
            } else {
                const targetCodeSection: number = codeTemplates.find(
                    (codeTemplate) => codeTemplate.id === initial.draggableId
                ).section;
                if (targetCodeSection === ExceptionsRequirementsPanelTypes.Requirements) {
                    dispatch(setTargetDropPanel(DroppableSections.Requirements));
                } else dispatch(setTargetDropPanel(DroppableSections.Exceptions));
            }
        } else dispatch(setTargetDropPanel(initial.source.droppableId as DroppableSections));
    };

    const codeDragEnd = (result: DropResult) => {
        const { destination, source } = result;
        if (!destination) {
            return;
        }
        if (
            (destination.droppableId === source.droppableId &&
                destination.index === source.index) ||
            destination.droppableId === DroppableSections.Codebook
        ) {
            return;
        }
        if (
            destination.droppableId === DroppableSections.Exceptions ||
            destination.droppableId === DroppableSections.Requirements ||
            destination.droppableId === DroppableSections.RequirementsCode ||
            destination.droppableId === DroppableSections.ExceptionsCode
        ) {
            const targetColumn =
                destination.droppableId === DroppableSections.Exceptions ||
                destination.droppableId === DroppableSections.ExceptionsCode
                    ? ExceptionsRequirementsPanelTypes.Exceptions
                    : ExceptionsRequirementsPanelTypes.Requirements;
            const targetCodesCopy: Paragraph[] = JSON.parse(
                JSON.stringify(
                    paragraphsList.filter((paragraph) => paragraph.section === targetColumn)
                )
            );
            if (destination.droppableId === source.droppableId) {
                dispatch(reorderColumnThunk(source.index, destination.index, targetCodesCopy));
            } else if (source.droppableId === DroppableSections.Codebook) {
                const destinationCodeSequence = targetCodesCopy[destination.index].sequence;
                dispatch(
                    addFromCodebookThunk(source.index, destinationCodeSequence, targetColumn)
                );
            }
        } else if (destination.droppableId.includes(DroppableSections.TaxCodes)) {
            const targetId = destination.droppableId.split('+')[1];
            const targetTax = examOrderTaxes.find((tax) => tax.id === targetId);
            const codesCopy: DocumentCode[] = JSON.parse(JSON.stringify(targetTax.codes));
            if (destination.droppableId === source.droppableId) {
                const movedItem = codesCopy.find((item, index) => index === source.index);
                codesCopy.splice(source.index, 1);
                codesCopy.splice(destination.index, 0, movedItem);
                const newCodesIds = codesCopy.map((code) => code.id);
                dispatch(setOrderTaxDocumentCodeThunk(orderId, targetId, newCodesIds));
            } else if (source.droppableId === DroppableSections.Codebook) {
                dispatch(dragCodeToTaxThunk(source.index, targetId));
            }
        } else if (
            destination.droppableId === DroppableSections.Legal ||
            destination.droppableId === DroppableSections.FullScreenLegal
        ) {
            const body = codeTemplates.find((ct) => ct.id === result.draggableId).body;
            const val = presentLegal;
            let ret = '';
            if (val === '') ret = body;
            else ret = val + '<p>' + body + '</p>';
            dispatch(setLegalDescriptionEditorValue(ret));
        } else if (
            destination.droppableId === DroppableSections.Vesting ||
            destination.droppableId === DroppableSections.FullScreenVesting
        ) {
            const body = codeTemplates.find((ct) => ct.id === result.draggableId).body;
            const val = presentVesting;
            let ret = '';
            if (val === '') ret = body;
            else ret = val + '<p>' + body + '</p>';
            dispatch(setVestingOwnersEditorValue(ret));
        } else if (destination.droppableId === DroppableSections.UploadKeyDocumentForm) {
            const newCodeTemplate = codeTemplates.find(
                (codeTemplate) => codeTemplate.id === result.draggableId
            );
            if (destination.droppableId === source.droppableId) {
                const codesCopy: CodeTemplate[] = JSON.parse(
                    JSON.stringify(keyDocumentFormData.codes)
                );
                const movedItem = codesCopy.find((item, index) => index === source.index);
                codesCopy.splice(source.index, 1);
                codesCopy.splice(destination.index, 0, movedItem);
                dispatch(
                    setKeyDocumentFormData({
                        ...keyDocumentFormData,
                        ['codes']: codesCopy
                    })
                );
            } else if (newCodeTemplate && newCodeTemplate.id) {
                dispatch(
                    setKeyDocumentFormData({
                        ...keyDocumentFormData,
                        ['codes']: [...keyDocumentFormData.codes, ...[newCodeTemplate]]
                    })
                );
            }
        } else if (destination.droppableId === DroppableSections.UploadTaxForm) {
            const newCodeTemplate = codeTemplates.find(
                (codeTemplate) => codeTemplate.id === result.draggableId
            );
            if (destination.droppableId === source.droppableId) {
                const codesCopy: CodeTemplate[] = JSON.parse(
                    JSON.stringify(taxFormData.codes)
                );
                const movedItem = codesCopy.find((item, index) => index === source.index);
                codesCopy.splice(source.index, 1);
                codesCopy.splice(destination.index, 0, movedItem);
                dispatch(
                    setTaxFormData({
                        ...taxFormData,
                        ['codes']: codesCopy
                    })
                );
            } else if (newCodeTemplate && newCodeTemplate.id) {
                dispatch(
                    setTaxFormData({
                        ...taxFormData,
                        ['codes']: [...taxFormData.codes, ...[newCodeTemplate]]
                    })
                );
            }
        } else if (
            destination.droppableId.includes(DroppableSections.FullScreenDocumentRows)
        ) {
            const newCodeTemplate = codeTemplates.find(
                (codeTemplate) => codeTemplate.id === result.draggableId
            );
            const documentGroup = destination.droppableId.split('+')[1];
            const documentId = destination.droppableId.split('+')[2];
            if (newCodeTemplate && newCodeTemplate.id) {
                switch (Number(documentGroup)) {
                    case DocumentGroup.keyDocument:
                        dispatch(
                            addOrderDocumentCodeThunk(orderId, documentId, [
                                newCodeTemplate.id
                            ])
                        );
                        break;
                    case DocumentGroup.searchPackage:
                        dispatch(
                            addOrderSearchPackageDocumentCodeThunk(orderId, documentId, [
                                newCodeTemplate.id
                            ])
                        );
                        break;
                }
            }
        } else if (destination.droppableId.includes(DroppableSections.InstrumentCard)) {
            const body = codeTemplates.find((ct) => ct.id === result.draggableId).body;
            const editorId = destination.droppableId.replace('instrumentCard_', 'editor_');
            const editorRef = tinymce.get(editorId);
            const val = editorRef?.getContent();
            let ret = '';
            if (val === '') ret = body;
            else ret = val + '<p>' + body + '</p>';

            editorRef?.setContent(ret);
            setTimeout(() => {
                editorRef?.focus();
            }, 100);
        }
    };

    const handleKeyDocumentFormOpen = (open: boolean) => {
        dispatch(setKeyDocumentFormOpen(open));
    };

    const handleSearchPackageFormOpen = (open: boolean) => {
        dispatch(setSearchPackageFormOpen(open));
    };

    const handleStartersAndPriorsFormOpen = (open: boolean) => {
        dispatch(setStartersAndPriorsFormOpen(open));
    };

    return (
        <DragDropContext
            onBeforeCapture={codeBeforeCapture}
            onDragStart={handleDragStart}
            onBeforeDragStart={codeDragStart}
            onDragEnd={(result) => {
                codeDragEnd(result);
                dispatch(setDragging(false));
            }}>
            <Outlet />
            {isUploadKeyDocumentFormOpen && (
                <Suspense fallback={<InlineLoadingSpinner />}>
                    <UploadKeyDocumentForm
                        isOpen={isUploadKeyDocumentFormOpen}
                        handleOpen={handleKeyDocumentFormOpen}
                    />
                </Suspense>
            )}
            {isUploadSearchPackageFormOpen && (
                <Suspense fallback={<InlineLoadingSpinner />}>
                    <UploadSearchPackageForm
                        isOpen={isUploadSearchPackageFormOpen}
                        handleOpen={handleSearchPackageFormOpen}
                    />
                </Suspense>
            )}
            {isUploadStartersAndPriorsFormOpen && (
                <Suspense fallback={<InlineLoadingSpinner />}>
                    <UploadStartersAndPriorsForm
                        isOpen={isUploadStartersAndPriorsFormOpen}
                        handleOpen={handleStartersAndPriorsFormOpen}
                    />
                </Suspense>
            )}
        </DragDropContext>
    );
};

export default WorkbenchLayout;
