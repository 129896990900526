import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import api from 'core/api';
import { handleError } from 'core/helpers/errorHandler';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { getAllExceptionsAndRequirementsThunk } from 'core/features/exceptions/exceptionsSlice';
import {
    SnackbarSeverity,
    SnackbarSuccessMessage,
    ERROR_TYPES,
    PartyRoles
} from 'core/constants/common';
import { sortCodeTemplatesByAlphabet } from 'core/helpers/sortCodeTemplatesByAlphabet';
import { NewTaxDocument, Tax } from 'types/taxes';
import {
    CreatePulseDocumentFileDto,
    DocumentCode,
    FullPulseDocumentFile,
    PulseDocumentFile,
    PulseDocumentPartyField
} from 'types/dataModels';
import { CodeTemplate } from 'types/codebook';
import { FileUploadError } from 'core/helpers/errors';
import { setFileUploadError } from 'core/features/fileDropzone/fileDropzoneSlice';
import { overrideExamDocumentFile } from 'core/api/examBlobDocumentFile';
import {
    getExamOrderSearchPackageGroupDataThunk,
    tagUntagDocumentThunk
} from '../examOrderSearchPackageGroup/examOrderSearchPackageGroupSlice';

interface ExamOrderTaxesState {
    examOrderTaxes: Array<Tax>;
}

const initialState: ExamOrderTaxesState = {
    examOrderTaxes: []
};

const examOrderTaxesSlice = createSlice({
    name: 'examOrderSearchReport',
    initialState,
    reducers: {
        /**
         * Set taxes data from BE to state
         * @param state Slice state
         * @param action Payload with list of taxes to set
         */
        setExamOrderTaxesData(state: ExamOrderTaxesState, action: PayloadAction<Array<Tax>>) {
            state.examOrderTaxes = action.payload;
        },
        /**
         * Update tax document data
         * @param state Slice state
         * @param action Payload object with tax ID and updated document data
         */
        updateExamOrderTaxesData(
            state: ExamOrderTaxesState,
            action: PayloadAction<{ taxId: string; updatedDocument: Tax }>
        ) {
            const targetDocumentIndex = state.examOrderTaxes.findIndex(
                (tax) => tax.id === action.payload.taxId
            );
            state.examOrderTaxes[targetDocumentIndex] = action.payload.updatedDocument;
        },
        /**
         * Upload tax file data
         * @param state Slice state
         * @param action Payload object with tax ID and uploaded file data
         */
        uploadExamOrderTaxFile(
            state: ExamOrderTaxesState,
            action: PayloadAction<{
                taxId: string;
                uploadResult: FullPulseDocumentFile[] | PulseDocumentFile[];
            }>
        ) {
            const { taxId, uploadResult } = action.payload;
            const targetDocumentIndex = state.examOrderTaxes.findIndex(
                (tax) => tax.id === taxId
            );
            state.examOrderTaxes[targetDocumentIndex] = {
                ...state.examOrderTaxes[targetDocumentIndex],
                hasPulseFiles: true,
                files: [
                    ...state.examOrderTaxes[targetDocumentIndex].files,
                    ...uploadResult.map((file) => ({
                        id: file.id,
                        fileName: file.fileName,
                        isTagged: file.isTagged,
                        isHtmlIndexFile: file.isHtmlIndexFile
                    }))
                ]
            };
        },
        /**
         * Remove tax file data
         * @param state Slice state
         * @param action Payload object with tax ID and ID of the file to remove
         */
        removeExamOrderTaxFile(
            state: ExamOrderTaxesState,
            action: PayloadAction<{ taxId: string; fileId: string }>
        ) {
            const targetDocumentIndex = state.examOrderTaxes.findIndex(
                (tax) => tax.id === action.payload.taxId
            );
            const targetFileIndex = state.examOrderTaxes[targetDocumentIndex].files.findIndex(
                (file) => file.id === action.payload.fileId
            );
            state.examOrderTaxes[targetDocumentIndex].files.splice(targetFileIndex, 1);
        },
        /**
         * Add a new tax data
         * @param state Slice state
         * @param action Payload with the Tax object to add
         */
        addNewExamOrderTax(state: ExamOrderTaxesState, action: PayloadAction<Tax>) {
            state.examOrderTaxes.push(action.payload);
        },
        /**
         * Update the codes of an existing tax document
         * @param state Slice state
         * @param action Payload with the Tax ID and array of codes to set
         */
        updateDocumentCodes(
            state: ExamOrderTaxesState,
            action: PayloadAction<{
                taxId: string;
                codes: DocumentCode[];
            }>
        ) {
            const targetDocument = state.examOrderTaxes.find(
                (tax) => tax.id === action.payload.taxId
            );
            targetDocument.codes = action.payload.codes;
        },
        /**
         * Add a party to an existing tax document
         * @param state Slice state
         * @param action Payload with the Tax ID and new party object to add
         */
        addParty(
            state: ExamOrderTaxesState,
            action: PayloadAction<{ taxId: string; newParty: PulseDocumentPartyField }>
        ) {
            const targetTaxDoc = state.examOrderTaxes.find(
                (taxDocument) => taxDocument.id === action.payload.taxId
            );
            targetTaxDoc.parties.push(action.payload.newParty);
        },
        /**
         * Update a party in an existing tax document
         * @param state Slice state
         * @param action Payload with the Tax ID, party ID, and updated party name
         */
        updateParty(
            state: ExamOrderTaxesState,
            action: PayloadAction<{
                taxId: string;
                partyId: string;
                value: string;
                first: string;
                middle: string;
                last: string;
            }>
        ) {
            const targetTaxDoc = state.examOrderTaxes.find(
                (taxDocument) => taxDocument.id === action.payload.taxId
            );
            const targetParty = targetTaxDoc.parties.find(
                (party) => party.id === action.payload.partyId
            );
            targetParty.first = action.payload.first;
            targetParty.middle = action.payload.middle;
            targetParty.last = action.payload.last;
            targetParty.displayValue = action.payload.value;
        },
        /**
         * Delete a party in an existing tax document
         * @param state Slice state
         * @param action Payload with the Tax ID and ID of the party to delete
         */
        deleteParty(
            state: ExamOrderTaxesState,
            action: PayloadAction<{ taxId: string; partyId: string }>
        ) {
            const targetTaxDoc = state.examOrderTaxes.find(
                (taxDocument) => taxDocument.id === action.payload.taxId
            );
            const targetPartyIndex = targetTaxDoc.parties.findIndex(
                (party) => party.id === action.payload.partyId
            );
            targetTaxDoc.parties.splice(targetPartyIndex, 1);
        },
        /**
         * Delete an existing tax document
         * @param state Slice state
         * @param action Payload with the Tax ID to delete
         */
        deleteTaxDocument(
            state: ExamOrderTaxesState,
            action: PayloadAction<{ taxId: string }>
        ) {
            const targetTaxDocIndex = state.examOrderTaxes.findIndex(
                (taxDocument) => taxDocument.id === action.payload.taxId
            );
            state.examOrderTaxes.splice(targetTaxDocIndex, 1);
        }
    }
});

export const {
    setExamOrderTaxesData,
    updateExamOrderTaxesData,
    uploadExamOrderTaxFile,
    removeExamOrderTaxFile,
    addNewExamOrderTax,
    updateDocumentCodes,
    addParty,
    updateParty,
    deleteParty,
    deleteTaxDocument
} = examOrderTaxesSlice.actions;

/**
 * Fetch exam order tax data from BE
 * @param {string} orderId
 * @returns {AppThunk}
 */
export const fetchExamOrderTaxesDataThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderTaxes.getExamOrderTaxes(orderId);
            dispatch(setExamOrderTaxesData(response));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get exam order taxes data: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update exam order tax data from BE
 * @param {string} orderId
 * @param {string} documentId
 * @param {Tax} data
 * @param {HTMLInputElement | HTMLTextAreaElement} ref
 * @returns {AppThunk}
 */
export const updateExamOrderTaxesDataThunk =
    (
        orderId: string,
        documentId: string,
        data: NewTaxDocument,
        ref?: HTMLInputElement | HTMLTextAreaElement
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderTaxes.updateExamOrderTaxDocument(
                orderId,
                documentId,
                data
            );
            if (ref) ref.focus();
            dispatch(
                updateExamOrderTaxesData({ taxId: documentId, updatedDocument: response })
            );
            dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update exam order taxes data: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Upload image in exam order tax file
 * @param {string} orderId
 * @param {string} documentId
 * @param {File[]} files
 * @returns {AppThunk}
 */
export const newExamOrderTaxesFileThunk =
    (orderId: string, documentId: string, files: File[]): AppThunk =>
    async (dispatch) => {
        try {
            await api.examBlobDocumentFile.postExamDocumentFile(
                orderId,
                documentId,
                files,
                false,
                true
            );
            dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
            dispatch(fetchExamOrderTaxesDataThunk(orderId));
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Upload exam order tax file: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * Override image in exam order tax file
 * @param {string} orderId
 * @param {Tax} tax
 * @param {File[]} files
 * @returns {AppThunk}
 */
export const overrideExamOrderTaxesFileThunk =
    (orderId: string, tax: Tax, files: File[]): AppThunk =>
    async (dispatch) => {
        try {
            await overrideExamDocumentFile(orderId, tax.id, tax.files[0]?.id, files, true);
            dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
            dispatch(fetchExamOrderTaxesDataThunk(orderId));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: SnackbarSuccessMessage.UpdateDocumentSuccess,
                    severity: SnackbarSeverity.Success
                })
            );
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Upload exam order tax file: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * Override image in exam order tax file
 * @param {string} orderId
 * @param {Tax} tax
 * @param {File[]} files
 * @returns {AppThunk}
 */
export const appendExamOrderTaxesFileThunk =
    (orderId: string, tax: Tax, files: File[]): AppThunk =>
    async (dispatch) => {
        try {
            await overrideExamDocumentFile(orderId, tax.id, tax.files[0]?.id, files, false);
            dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
            dispatch(fetchExamOrderTaxesDataThunk(orderId));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: SnackbarSuccessMessage.UpdateDocumentSuccess,
                    severity: SnackbarSeverity.Success
                })
            );
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Upload exam order tax file: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * Remove exam order tax file
 * @param {string} orderId
 * @param {string} documentId
 * @param {string} fileId
 * @returns {AppThunk}
 */
export const removeExamOrderTaxesFileThunk =
    (orderId: string, documentId: string, fileId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examBlobDocumentFile.deleteExamDocumentFile(orderId, documentId, fileId);
            dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
            dispatch(removeExamOrderTaxFile({ taxId: documentId, fileId }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove exam order tax file: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new tax files to an order
 * @param {string} orderId ID of the order
 * @param {File[]} files
 * @returns {AppThunk}
 */
export const uploadNewTaxFiles = async (
    orderId: string,
    files: File[]
): Promise<CreatePulseDocumentFileDto[]> => {
    try {
        return await api.examBlobDocumentFile.uploadImage(orderId, files, true);
    } catch (err) {
        handleError(err, ERROR_TYPES.fileUpload);
    }
};

/**
 * Upload new Exam Order Tax document
 * @param {string} orderId
 * @param {Function} handleCancel Clear and close the form
 * @returns {AppThunk}
 */
export const uploadNewExamOrderTaxThunk =
    (orderId: string, handleCancel: Function): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { formData } = getState().uploadTaxFormData;
            const files = getState().fileDropzoneData.files;

            let uploadedFiles: CreatePulseDocumentFileDto[] = [];
            if (files?.length) {
                uploadedFiles = await uploadNewTaxFiles(orderId, files);
            }
            const response = await api.examOrderUploadTaxDocument.uploadTaxWithNoImage(
                orderId,
                formData,
                uploadedFiles
            );
            dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
            dispatch(addNewExamOrderTax(response));
            handleCancel();
            dispatch(
                setSnackbarState({
                    open: true,
                    message: SnackbarSuccessMessage.AddDocumentSuccess,
                    severity: SnackbarSeverity.Success
                })
            );
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * Add a list of codes to an existing Tax document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add the codes to
 * @param {number[]} codesArray IDs of the codes to add to the document
 * @returns {AppThunk}
 */
export const addOrderTaxDocumentCodeThunk =
    (orderId: string, documentId: string, codesArray: string[]): AppThunk =>
    async (dispatch) => {
        try {
            const addedCodes = await api.examOrderDocumentFields.addDocumentCodes(
                orderId,
                documentId,
                codesArray
            );
            dispatch(updateDocumentCodes({ taxId: documentId, codes: addedCodes }));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add Document Code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a code from an existing Tax document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to remove a code from
 * @param {number} codeId ID of the code to remove from the document
 * @returns {AppThunk}
 */
export const removeOrderTaxDocumentCodeThunk =
    (orderId: string, documentId: string, codeId: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { examOrderTaxes } = getState().examOrderTaxesData;
            const targetTaxDocument = examOrderTaxes.find(
                (taxDoc) => taxDoc.id === documentId
            );
            const targetCodeIndex = targetTaxDocument.codes.findIndex(
                (code) => code.id === codeId
            );
            const codesArrayCopy = [...targetTaxDocument.codes];
            codesArrayCopy.splice(targetCodeIndex, 1);
            const newCodesIds = codesArrayCopy.reduce(
                (acc: string[], currentValue) => [...acc, currentValue.id],
                []
            );
            const addedCodes = await api.examOrderDocumentFields.setDocumentCodes(
                orderId,
                documentId,
                newCodesIds
            );
            dispatch(updateDocumentCodes({ taxId: documentId, codes: addedCodes }));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove Document Code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Set the existing codes of a Tax document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to remove a code from
 * @param {number[]} codesArray IDs of the codes to set
 * @returns {AppThunk}
 */
export const setOrderTaxDocumentCodeThunk =
    (orderId: string, documentId: string, codesArray: string[]): AppThunk =>
    async (dispatch) => {
        try {
            const newCodes = await api.examOrderDocumentFields.setDocumentCodes(
                orderId,
                documentId,
                codesArray
            );
            dispatch(updateDocumentCodes({ taxId: documentId, codes: newCodes }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set Document Code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Drag and drop a code into a Tax document
 * @param {number} sourceIndex Position of the dragged code in the codebook droppable
 * @param {string} targetTax ID of the Tax document the code was dropped to
 * @returns {AppThunk}
 */
export const dragCodeToTaxThunk =
    (sourceIndex: number, targetTax: string): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { codeTemplates, lookupInput, targetSection } = getState().examCodeBookData;
            const currentOrder = getState().currentExamOrderData.currentExamOrder.id;

            const filterBySection = (template: CodeTemplate) => {
                if (targetSection === null) return true;
                else if (template.section === targetSection) {
                    return true;
                }
                return false;
            };
            const handleFilterCodes = (template: CodeTemplate) => {
                if (!lookupInput) return true;
                else if (
                    template.code.toUpperCase().includes(lookupInput.toUpperCase()) ||
                    template.body.toUpperCase().includes(lookupInput.toUpperCase()) ||
                    template.label.toUpperCase().includes(lookupInput.toUpperCase())
                ) {
                    return true;
                }
                return false;
            };
            const codeToAdd = codeTemplates
                .filter(handleFilterCodes)
                .filter(filterBySection)
                .sort(sortCodeTemplatesByAlphabet)
                .find((code, index) => index === sourceIndex);
            const newCodes = await api.examOrderDocumentFields.addDocumentCodes(
                currentOrder,
                targetTax,
                [codeToAdd.id]
            );
            dispatch(getAllExceptionsAndRequirementsThunk(currentOrder));
            dispatch(updateDocumentCodes({ taxId: targetTax, codes: newCodes }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Set Document Code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new party with blank fields to an existing Tax document
 * @param {string} orderId ID of the order
 * @param {string} taxId ID of the Tax document to add a party to
 * @param {boolean} isGrantee flag indicates party is Grantor Type or Grantee Type
 * @param {PartyRoles} partyRole party role
 * @returns {AppThunk}
 */
export const addTaxDocumentPartyThunk =
    (orderId: string, taxId: string, isGrantee: boolean, partyRole: PartyRoles): AppThunk =>
    async (dispatch) => {
        const newPartyObj: PulseDocumentPartyField = {
            id: uuidv4(),
            fieldNameId: uuidv4(),
            isGrantee,
            role: partyRole,
            first: '',
            middle: '',
            last: '',
            businessName: '',
            displayValue: ''
        };
        try {
            const response = await api.examOrderDocumentFields.addDocumentPartyApi(
                orderId,
                taxId,
                newPartyObj
            );
            dispatch(addParty({ taxId, newParty: response.field }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add document party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a party from an existing Tax document
 * @param {string} orderId ID of the order
 * @param {string} taxId ID of the Tax document to add a party to
 * @param {string} partyId ID of the party to remove from the document
 * @returns {AppThunk}
 */
export const deleteDocumentPartyThunk =
    (orderId: string, taxId: string, partyId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderDocumentFields.deleteDocumentPartyApi(orderId, taxId, partyId);
            dispatch(deleteParty({ taxId, partyId }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove document party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the name of a party in an existing Tax document
 * @param {string} orderId ID of the order
 * @param {string} taxId ID of the Tax document that owns the party
 * @param {string} party party to update
 * @param {string} value Updated party name
 * @param {HTMLInputElement | HTMLTextAreaElement} ref
 * @returns {AppThunk}
 */
export const updateDocumentPartyThunk =
    (
        orderId: string,
        taxId: string,
        party: PulseDocumentPartyField,
        value: string,
        ref?: HTMLInputElement
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.examOrderDocumentFields.updateDocumentPartyApi(
                orderId,
                taxId,
                party.id,
                value
            );
            if (ref) ref.focus();
            const { id: partyId, first, middle, last } = response;
            dispatch(updateParty({ taxId, partyId, value, first, middle, last }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update document party: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Delete an existing Tax document
 * @param {string} orderId ID of the order
 * @param {string} taxId ID of the Tax document to delete
 * @returns {AppThunk}
 */
export const deleteTaxDocumentThunk =
    (orderId: string, taxId: string): AppThunk =>
    async (dispatch) => {
        try {
            await dispatch(tagUntagDocumentThunk(orderId, taxId, false));
            await dispatch(getExamOrderSearchPackageGroupDataThunk(orderId));
            dispatch(deleteTaxDocument({ taxId }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove tax document: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default examOrderTaxesSlice.reducer;
