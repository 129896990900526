import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { SnackbarSeverity, SnackbarSuccessMessage } from 'core/constants/common';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import { AppThunk } from 'core/store/store';
import { FileUploadError } from 'core/helpers/errors';
import { setFileUploadError } from 'core/features/fileDropzone/fileDropzoneSlice';
import {
    Directions,
    DocumentCode,
    DocumentNote,
    ExamOrderStartersAndPriorsGroupType,
    PulseDocumentFile,
    StartersAndPriorsDocument,
    SortColumnNames,
    CreatePulseDocumentFileDto,
    CommonDocument,
    ValueDocLink
} from 'types/dataModels';
import { getAllExceptionsAndRequirementsThunk } from 'core/features/exceptions/exceptionsSlice';
import { fetchExamOrderLegalDescriptionData } from 'core/features/examOrderLegalDescription/examOrderLegalDescriptionSlice';
import { fetchExamOrderVestingData } from 'core/features/examOrderVesting/examOrderVestingSlice';
import { fetchFinalReviewDataThunk } from 'core/features/examOrderFinalReview/examOrderFinalReviewSlice';
import { PriorFileStatements } from 'types/dataModels';
import {
    deleteTaxDocumentThunk,
    fetchExamOrderTaxesDataThunk
} from '../examOrderTaxes/examOrderTaxesSlice';

interface ExamOrderStartersAndPriorsGroupState {
    /**
     *exam order starters and priors group array
     */
    examOrderStartersAndPriorsGroup: ExamOrderStartersAndPriorsGroupType[];
}

const initialState: ExamOrderStartersAndPriorsGroupState = {
    examOrderStartersAndPriorsGroup: []
};

const examOrderStartersAndPriorsGroupSlice = createSlice({
    name: 'examOrderStartersAndPriorsGroup',
    initialState,
    reducers: {
        /**
         * Set starters and priors group data from BE to state
         * @param state Slice state
         * @param action Payload with starters and priors group data to set
         */
        setExamOrderStartersAndPriorsGroupData(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<ExamOrderStartersAndPriorsGroupType[]>
        ) {
            state.examOrderStartersAndPriorsGroup = action.payload;
        },
        /**
         * Set the tagged state of a starters and priors document
         * @param state Slice state
         * @param action Payload object with the document ID and isTagged value to assign to the document
         */
        setIsDocumentTagged(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{ docId: string; isTagged: boolean }>
        ) {
            const targetGroup = state.examOrderStartersAndPriorsGroup.find((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            targetGroup.documents.find(
                (document) => document.id === action.payload.docId
            ).isTagged = action.payload.isTagged;
            /* eslint-disable no-param-reassign */
            targetGroup.taggedCount = targetGroup.documents.reduce(
                (count, doc) => (doc.isTagged ? ++count : count),
                0
            );
            targetGroup.unTaggedCount = targetGroup.documents.reduce(
                (count, doc) => (!doc.isTagged ? ++count : count),
                0
            );
        },
        /**
         * Set the tagged state of all files attached to a starters and priors document
         * @param state Slice state
         * @param action Payload with the ID of the document and the isTagged value to assign to the files
         */
        setDocumentFilesIsTagged(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{ docId: string; isTagged: boolean }>
        ) {
            const targetGroup = state.examOrderStartersAndPriorsGroup.find((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            targetGroup.documents
                .find((document) => document.id === action.payload.docId)
                .files.forEach((file) => (file.isTagged = action.payload.isTagged));
        },
        /**
         * Set the tagged state of all files attached to a key document
         * @param state Slice state
         * @param action Payload with the ID of the document and the includeAttachments value to assign to the files
         */
        setDocumentIncludeAttached(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{ docId: string; includeAttachments: boolean }>
        ) {
            const targetGroup = state.examOrderStartersAndPriorsGroup.find((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            targetGroup.documents.find(
                (document) => document.id === action.payload.docId
            ).includeAttachments = action.payload.includeAttachments;
        },
        /**
         * Set the tagged state of all starters and priors documents in a group
         * @param state Slice state
         * @param action Payload with the ID of the group and the isTagged value to assign to the documents
         */
        setAllDocsIsTagged(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{ groupId: string; isTagged: boolean }>
        ) {
            const targetGroup = state.examOrderStartersAndPriorsGroup.find(
                (group) => group.groupId === action.payload.groupId
            );
            targetGroup.documents.forEach((doc) => (doc.isTagged = action.payload.isTagged));
            /* eslint-disable no-param-reassign */
            targetGroup.taggedCount = targetGroup.documents.reduce(
                (count, doc) => (doc.isTagged ? ++count : count),
                0
            );
            targetGroup.unTaggedCount = targetGroup.documents.reduce(
                (count, doc) => (!doc.isTagged ? ++count : count),
                0
            );
        },
        /**
         * Add codes to a starters and priors document
         * @param state Slice state
         * @param action Payload with the document ID and array of codes to add
         */
        addDocumentCodes(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{
                docId: string;
                codes: DocumentCode[];
            }>
        ) {
            const targetGroup = state.examOrderStartersAndPriorsGroup.find((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            const targetDocument = targetGroup.documents.find(
                (doc) => doc.id === action.payload.docId
            );
            targetDocument.codes = [...action.payload.codes];
        },
        /**
         * Update the recorded date of a starters and priors document
         * @param state Slice state
         * @param action Payload with the group ID, document ID and updated ISO date string
         */
        updateRecordedDate(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{
                groupId: string;
                docId: string;
                newDate: string;
            }>
        ) {
            const targetGroup = state.examOrderStartersAndPriorsGroup.find(
                (group) => group.groupId === action.payload.groupId
            );
            const targetDocument = targetGroup.documents.find(
                (doc) => doc.id === action.payload.docId
            );
            targetDocument.recordedDate = action.payload.newDate;
        },
        /**
         * Untag the image of a starters and priors document
         * @param state Slice state
         * @param action Payload with the document ID to delete the image from
         */
        deleteDocumentImage(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{
                docId: string;
            }>
        ) {
            const targetGroup = state.examOrderStartersAndPriorsGroup.find((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            const targetDocument = targetGroup.documents.find(
                (doc) => doc.id === action.payload.docId
            );
            targetDocument.hasPulseFiles = false;
            targetDocument.includeAttachments = false;
        },
        /**
         * Update a file in a starters and priors document
         * @param state Slice state
         * @param action Payload with the document ID and the new file to set
         */
        updateDocumentFile(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{ docId: string; files: PulseDocumentFile[] }>
        ) {
            const targetGroup = state.examOrderStartersAndPriorsGroup.find((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            const targetDocument = targetGroup.documents.find(
                (doc) => doc.id === action.payload.docId
            );
            targetDocument.files = action.payload.files;
            targetDocument.hasPulseFiles = true;
            targetDocument.includeAttachments = true;
        },
        /**
         * Update the note in a starters and priors
         * @param state Slice state
         * @param action Payload with the document ID and the new note to set
         */
        updateDocumentNote(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{ docId: string; documentNote: DocumentNote }>
        ) {
            const targetGroupAr = state.examOrderStartersAndPriorsGroup.filter((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            const targetDocument: Array<StartersAndPriorsDocument> = [];
            targetGroupAr.forEach((targetGroup) => {
                targetDocument.push(
                    targetGroup.documents.find((doc) => doc.id === action.payload.docId)
                );
            });
            targetDocument.forEach((item) => {
                const noteToBeUpdated = item.notes.findIndex(
                    (note) => note.id === action.payload.documentNote.id
                );
                if (noteToBeUpdated !== -1) {
                    item.notes[noteToBeUpdated] = action.payload.documentNote;
                } else {
                    item.notes.push(action.payload.documentNote);
                }
            });
        },
        /**
         * Remove a note in a starters and priors
         * @param state Slice state
         * @param action Payload with the document ID and ID of the note to remove
         */
        removeDocumentNote(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{ docId: string; noteId: string }>
        ) {
            const targetGroupAr = state.examOrderStartersAndPriorsGroup.filter((group) =>
                group.documents.map((doc) => doc.id).includes(action.payload.docId)
            );
            const targetDocument: Array<StartersAndPriorsDocument> = [];
            targetGroupAr.forEach((targetGroup) => {
                targetDocument.push(
                    targetGroup.documents.find((doc) => doc.id === action.payload.docId)
                );
            });
            let targetNoteIndex;
            targetDocument.forEach((item) => {
                targetNoteIndex = item.notes.findIndex(
                    (note) => note.id === action.payload.noteId
                );
                item.notes.splice(targetNoteIndex, 1);
            });
        },
        /**
         * Updates currentSortingColumn and sortingDirections data of each group
         * @param state Slice state
         * @param action Payload with groupId, currentSortingColumn and sortingDirections
         */
        setSorting(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{
                groupId: string;
                currentSortingColumn: SortColumnNames;
                sortingDirections: Directions;
            }>
        ) {
            const { groupId, currentSortingColumn, sortingDirections } = action.payload;
            const groupToUpdateIndex = state.examOrderStartersAndPriorsGroup.findIndex(
                (group) => group.groupId === groupId
            );
            if (groupToUpdateIndex !== -1) {
                state.examOrderStartersAndPriorsGroup[groupToUpdateIndex].sortingDirections =
                    sortingDirections;
                state.examOrderStartersAndPriorsGroup[
                    groupToUpdateIndex
                ].currentSortingColumn = currentSortingColumn;
            }
        },
        addCodeIntoStatement(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{
                documentId: string;
                targetStatementId: string;
                code: string;
                codeTemplateId: string;
                section: keyof PriorFileStatements;
                phraseText: string;
            }>
        ) {
            const {
                documentId,
                targetStatementId,
                code,
                codeTemplateId,
                section,
                phraseText
            } = action.payload;
            state.examOrderStartersAndPriorsGroup.forEach((group) => {
                group.documents.forEach((document) => {
                    if (document.id === documentId) {
                        const targetStatement = document.priorFileData.statements[
                            section
                        ].find((statement) => statement.id === targetStatementId);
                        targetStatement.code = code;
                        targetStatement.phraseText = phraseText;
                        targetStatement.codeTemplateId = codeTemplateId;
                    }
                });
            });
        },
        updateEditorContent(
            state: ExamOrderStartersAndPriorsGroupState,
            action: PayloadAction<{
                documentId: string;
                targetStatementId: string;
                content: string;
                valueDocLinks: ValueDocLink[];
                section: keyof PriorFileStatements;
            }>
        ) {
            const { documentId, targetStatementId, content, valueDocLinks, section } =
                action.payload;
            state.examOrderStartersAndPriorsGroup.forEach((group) => {
                group.documents.forEach((document) => {
                    if (document.id === documentId) {
                        const targetStatement = document.priorFileData.statements[
                            section
                        ].find((statement) => statement.id === targetStatementId);
                        if (targetStatement) {
                            targetStatement.text = content;
                            targetStatement.phraseText = content;
                            targetStatement.phraseDocLinks = valueDocLinks;
                        }
                    }
                });
            });
        }
    }
});

export const {
    setExamOrderStartersAndPriorsGroupData,
    setIsDocumentTagged,
    setDocumentFilesIsTagged,
    setDocumentIncludeAttached,
    setAllDocsIsTagged,
    addDocumentCodes,
    deleteDocumentImage,
    updateRecordedDate,
    updateDocumentFile,
    updateDocumentNote,
    removeDocumentNote,
    setSorting,
    addCodeIntoStatement,
    updateEditorContent
} = examOrderStartersAndPriorsGroupSlice.actions;

/**
 * Get exam order starters and priors data from BE
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const fetchExamOrderStartersAndPriorsGroupDataThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const response =
                await api.examOrderDocumentGroup.getExamOrderStartersAndPriorsGroup(orderId);
            dispatch(setExamOrderStartersAndPriorsGroupData(response));
        } catch (err) {
            dispatch(setExamOrderStartersAndPriorsGroupData([]));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get starters and priors groups: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Set the isTagged value of a Starters and Priors document
 * @param {string} orderId ID of the order
 * @param {string} docId ID of the document
 * @param {boolean} value isTagged value to assign to the document
 * @returns {AppThunk}
 */
export const tagUntagDocumentThunk =
    (orderId: string, docId: string, value: boolean): AppThunk =>
    async (dispatch) => {
        try {
            const {
                documentId: documentIdResponse,
                includeAttachments: includeAttachmentsResponse
            } = await api.documentTagUntag.apiSetDocumentIsTagged(orderId, docId, value);
            dispatch(setIsDocumentTagged({ docId, isTagged: value }));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            dispatch(
                setDocumentIncludeAttached({
                    docId: documentIdResponse,
                    includeAttachments: includeAttachmentsResponse
                })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Tag/untag starters and priors document: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Set the isTagged value of a Tax document
 * @param {string} orderId ID of the order
 * @param {string} docId ID of the document
 * @param {boolean} value isTagged value to assign to the document
 * @returns {AppThunk}
 */
export const tagUntagTaxDocumentThunk =
    (orderId: string, docId: string, value: boolean): AppThunk =>
    async (dispatch) => {
        try {
            if (value) {
                dispatch(tagUntagDocumentThunk(orderId, docId, true));
                dispatch(fetchExamOrderTaxesDataThunk(orderId));
            } else dispatch(deleteTaxDocumentThunk(orderId, docId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Tag/untag starters and priors document: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Set the isTagged value of all the files attached to a document
 * @param {string} orderId ID of the order
 * @param {string} docId ID of the document
 * @param {boolean} value isTagged value to assign to the files
 * @returns {AppThunk}
 */
export const tagUntagDocumentFilesThunk =
    (orderId: string, docId: string, value: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            await api.documentFileTagUntag.apiSetIncludeAttachments(orderId, docId, value);
            dispatch(
                setDocumentIncludeAttached({
                    docId,
                    includeAttachments: value
                })
            );
            dispatch(fetchExamOrderVestingData(orderId));
            dispatch(fetchExamOrderLegalDescriptionData(orderId));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
            if (getState().workbenchTabsData?.isFinalReviewVisible) {
                dispatch(fetchFinalReviewDataThunk(orderId));
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Tag/untag starters and priors document file: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Set the isTagged value of all the StartersAndPriors documents in a group
 * @param {string} orderId ID of the order
 * @param {string[]} documentIds IDs of the documents to update
 * @param {boolean} isTagged isTagged value to assign to the documents
 * @param {string} groupId ID of the group that contains the documents
 * @returns {AppThunk}
 */
export const tagAllDocumentsInGroupThunk =
    (orderId: string, documentIds: string[], isTagged: boolean, groupId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.documentTagUntag.apiSetAllDocsIsTagged(orderId, documentIds, isTagged);
            dispatch(
                setAllDocsIsTagged({
                    groupId,
                    isTagged
                })
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Tag/unTag document group: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add codes to an existing Starters and Priors document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add codes to
 * @param {number[]} codesArray IDs of the codes to add to the document
 * @returns {AppThunk}
 */
export const addOrderStartersAndPriorsDocumentCodeThunk =
    (orderId: string, documentId: string, codesArray: string[]): AppThunk =>
    async (dispatch) => {
        try {
            const addedCodes = await api.examOrderDocumentFields.addDocumentCodes(
                orderId,
                documentId,
                codesArray
            );
            dispatch(addDocumentCodes({ docId: documentId, codes: addedCodes }));
            dispatch(getAllExceptionsAndRequirementsThunk(orderId));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add Document Code: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Add a new Starters and Priors document to an existing order
 * @param {string} orderId ID of the order
 * @param {Function} cleanupFn cleanup function to (Clear and close the form) || (Clear the form)
 * @param {HTMLInputElement} ref
 * @returns {AppThunk}
 */
export const addNewStartersAndPriorsDocumentThunk =
    (
        orderId: string,
        cleanupFn?: () => void,
        ref?: HTMLInputElement | HTMLSelectElement
    ): AppThunk =>
    async (dispatch, getState) => {
        try {
            const { formData } = getState().uploadStartersAndPriorsFormData;
            const propertyId =
                getState().currentExamOrderData.currentExamOrder.properties?.[0]?.id;
            const files = getState().fileDropzoneData.files;

            let uploadedFiles: CreatePulseDocumentFileDto[] = [];
            if (files?.length) {
                uploadedFiles = await api.examBlobDocumentFile.uploadImage(orderId, files);
            }
            await api.examUploadDocument.uploadCommonDocumentWithNoImage(
                formData,
                uploadedFiles,
                orderId,
                propertyId
            );
            dispatch(fetchExamOrderStartersAndPriorsGroupDataThunk(orderId));
            if (typeof cleanupFn === 'function') cleanupFn();
            dispatch(
                setSnackbarState({
                    open: true,
                    message: SnackbarSuccessMessage.AddDocumentSuccess,
                    severity: SnackbarSeverity.Success
                })
            );
            if (ref) ref.focus();
        } catch (err) {
            if (err instanceof FileUploadError) {
                dispatch(
                    setFileUploadError({
                        error: true,
                        message: err.message
                    })
                );
            } else {
                dispatch(
                    setSnackbarState({
                        open: true,
                        message: `Add Starters and Priors Document: ${err.message}`,
                        severity: SnackbarSeverity.Error
                    })
                );
            }
        }
    };

/**
 * Replace a file attached to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to override the existing image
 * @param {string} fileId ID of the file to override
 * @param {Files} files
 * @returns {AppThunk}
 */
export const overrideExistingImageThunk =
    (orderId: string, documentId: string, fileId: string, files: File[]): AppThunk =>
    async (dispatch) => {
        try {
            await api.examBlobDocumentFile.overrideExamDocumentFile(
                orderId,
                documentId,
                fileId,
                files,
                false
            );
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Override existing starters and priors document image: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update the recorded date of an existing Starters and Priors document
 * @param {string} orderId ID of the order
 * @param {string} groupId ID of the group where the target document is found
 * @param {string} docId ID of the document to update
 * @param {string} newDate ISO date string representing the updated recorded date
 * @returns {AppThunk}
 */
export const updateRecordedDateThunk =
    (orderId: string, groupId: string, docId: string, newDate: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderDocumentFields.updateDocumentRecordedDateWithParagraphsApi(
                orderId,
                docId,
                newDate
            );
            dispatch(updateRecordedDate({ groupId, docId, newDate }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Update recorded date: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Upload a new image file to attach to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to upload an image to
 * @param {File[]} files File to attach to the document
 * @returns {AppThunk}
 */
export const uploadDocumentImageThunk =
    (orderId: string, documentId: string, files: File[]): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examBlobDocumentFile.postExamDocumentFile(
                orderId,
                documentId,
                files,
                true,
                true
            );
            dispatch(updateDocumentFile({ docId: documentId, files: result }));
        } catch (err) {
            dispatch(
                setFileUploadError({
                    error: true,
                    message: err.message
                })
            );
        }
    };

/**
 * Add a new note to an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document to add a note to
 * @param {string} documentNote String containing the document note
 * @returns {AppThunk}
 */
export const addNewNoteThunk =
    (orderId: string, documentId: string, documentNote: string): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrderDocumentNotes.addExamOrderDocumentNote(
                orderId,
                documentId,
                documentNote
            );
            dispatch(updateDocumentNote({ docId: documentId, documentNote: result }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add new document note: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Update a note in an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document that owns the note
 * @param {DocumentNote} documentNote Updated note object
 * @returns {AppThunk}
 */
export const updateNoteThunk =
    (orderId: string, documentId: string, documentNote: DocumentNote): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrderDocumentNotes.updateExamOrderDocumentNote(
                orderId,
                documentId,
                documentNote
            );
            dispatch(updateDocumentNote({ docId: documentId, documentNote: result }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Add/update new document note: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Remove a note from an existing document
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document that owns the note
 * @param {string} noteId ID of the note to remove
 * @returns {AppThunk}
 */
export const removeNoteThunk =
    (orderId: string, documentId: string, noteId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.examOrderDocumentNotes.deleteExamOrderDocumentNoteById(
                orderId,
                documentId,
                noteId
            );
            dispatch(removeDocumentNote({ docId: documentId, noteId }));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Remove document note: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Import legal, requirement, exceptions statements
 * @param {string} orderId ID of the order
 * @param {string} documentId ID of the document that owns the note
 * @param {boolean} canCloseWindow
 * @returns {AppThunk}
 */
export const postImportStatements =
    (orderId: string, documentId: string, canCloseWindow: boolean): AppThunk =>
    async (dispatch, getState) => {
        try {
            const examOrderStartersAndPriorsGroup =
                getState().examOrderStartersAndPriorsGroupData.examOrderStartersAndPriorsGroup;

            const {
                keepLanguageForRequirements,
                keepLanguageForExceptions,
                selectedLegals,
                selectedRequirements,
                selectedExceptions,
                selectedOthers
            } = getState().startersAndPriorsInformationData;

            let requiredDocument: CommonDocument = null;

            examOrderStartersAndPriorsGroup.forEach((group) => {
                group.documents.forEach((document) => {
                    if (document.id === documentId) {
                        requiredDocument = document;
                    }
                });
            });

            if (requiredDocument) {
                const legals = requiredDocument.priorFileData.statements?.legals?.map(
                    (legal) => {
                        return {
                            ...legal,
                            isImported: selectedLegals.includes(legal.id)
                        };
                    }
                );

                const requirements =
                    requiredDocument.priorFileData.statements?.requirements?.map(
                        (requirement) => {
                            return {
                                ...requirement,
                                isImported: selectedRequirements.includes(requirement.id),
                                keepLanguage: keepLanguageForRequirements.includes(
                                    requirement.id
                                ),
                                text:
                                    !keepLanguageForRequirements.includes(requirement.id) &&
                                    requirement.code
                                        ? requirement.phraseText
                                        : requirement.text
                            };
                        }
                    );

                const exceptions = requiredDocument.priorFileData.statements?.exceptions?.map(
                    (exception) => {
                        return {
                            ...exception,
                            isImported: selectedExceptions.includes(exception.id),
                            keepLanguage: keepLanguageForExceptions.includes(exception.id),
                            text:
                                !keepLanguageForExceptions.includes(exception.id) &&
                                exception.code
                                    ? exception.phraseText
                                    : exception.text
                        };
                    }
                );

                const others = requiredDocument.priorFileData.statements?.others?.map(
                    (other) => {
                        return {
                            ...other,
                            isImported: selectedOthers.includes(other.id)
                        };
                    }
                );

                const statements = {
                    legals,
                    requirements,
                    exceptions,
                    others
                };

                const response = await api.examOrderDocumentGroup.importPriorFile(
                    orderId,
                    documentId,
                    statements
                );
                await dispatch(setExamOrderStartersAndPriorsGroupData(response));
                await dispatch(fetchExamOrderLegalDescriptionData(orderId));
                await dispatch(fetchExamOrderVestingData(orderId));
                await dispatch(getAllExceptionsAndRequirementsThunk(orderId));
                setTimeout(() => {
                    if (canCloseWindow) {
                        window.close();
                    }
                }, 1000);
            }
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Import statments: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default examOrderStartersAndPriorsGroupSlice.reducer;
