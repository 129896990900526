import clsx from 'clsx';
import { Grid, Typography } from '@mui/material';
import { textStylesRoboto } from 'styles/common';
import { format } from 'date-fns';
import { Tax } from 'types/taxes';
import styles from './styles';

interface DatesTableProps {
    tax: Tax;
}

/**
 * Render dates table on tax section final review
 * @param {Tax} tax Tax object
 * @component
 * @returns {JSX.Element}
 */
const DatesTable = ({ tax }: DatesTableProps) => {
    const { body_strong_14_semibold, body_14_regular } = textStylesRoboto().classes;
    const { subSectionTitle, tableContainer, tableCell, cellLeft, cellRight } =
        styles().classes;
    return (
        <>
            <Typography className={clsx(body_strong_14_semibold, subSectionTitle)}>
                Dates
            </Typography>
            <Grid container className={clsx(tableContainer, body_14_regular)}>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Delinquent Date
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {tax.delinquentDate
                        ? format(new Date(tax.delinquentDate), 'MM/dd/yyyy')
                        : ''}
                </Grid>
                <Grid item xs={3} className={clsx(tableCell, cellLeft)}>
                    Pay Date
                </Grid>
                <Grid item xs={9} className={clsx(tableCell, cellRight)}>
                    {format(new Date(tax.payDate), 'MM/dd/yyyy')}
                </Grid>
            </Grid>
        </>
    );
};

export default DatesTable;
