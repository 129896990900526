import { getMergedExamDocumentFile } from 'core/api/examBlobDocumentFile';
import { CommonDocument, KeyDocument } from 'types/dataModels';
import { flattenDocuments } from 'core/helpers/flattenDocs';
import { handleError } from 'core/helpers/errorHandler';
import store, { RootState } from '../store/store';

export interface FileDetails {
    documentId: string;
    fileId: string;
}

const mergeDownloadImage = async (orderId?: string, fileDetails?: FileDetails[]) => {
    // const dispatch = useAppDispatch();
    // Get the state of the store
    const state: RootState = store.getState();

    const examOrderId = state.currentExamOrderData?.currentExamOrder?.id;
    if (!examOrderId && !orderId) return;

    const { currentDocSelectValue, currentDocFileIndex } = state.documentViewerData;

    const searchPackageDocuments: CommonDocument[] =
        state.examOrderSearchPackageGroupData.examOrderSearchPackageGroup.flatMap(
            (group) => group.documents
        );
    const startersAndPriorsDocuments: CommonDocument[] =
        state.examOrderStartersAndPriorsGroupData.examOrderStartersAndPriorsGroup.flatMap(
            (group) => group.documents
        );
    const taxDocuments = state.examOrderTaxesData.examOrderTaxes;
    const keyDocuments: KeyDocument[] = flattenDocuments(
        state.examOrderKeyDocumentGroupData.examOrderDocumentGroup
    );

    let filename = '';
    const filesToMerge: FileDetails[] = [];
    fileDetails.forEach((detail) => {
        if (detail.fileId) {
            const { documentId, fileId } = detail;
            filesToMerge.push({ documentId, fileId });
        } else {
            const documentToSearchFor = detail.documentId ?? currentDocSelectValue;
            const documentFileIndex = detail.documentId ? '0' : currentDocFileIndex;

            const kdFilename = keyDocuments.find((doc) => doc.id === documentToSearchFor)
                ?.files[currentDocFileIndex]?.fileName;
            const spFilename = searchPackageDocuments.find(
                (doc) => doc.id === documentToSearchFor
            )?.files[currentDocFileIndex]?.fileName;
            const stprFilename = startersAndPriorsDocuments.find(
                (doc) => doc.id === documentToSearchFor
            )?.files[currentDocFileIndex]?.fileName;
            const taxfilename = taxDocuments.find((doc) => doc.id === documentToSearchFor)
                ?.files[currentDocFileIndex]?.fileName;

            filename = taxfilename || kdFilename || spFilename || stprFilename;

            const keyDocumentId = keyDocuments.find((doc) => doc.id === documentToSearchFor)
                ?.files[documentFileIndex]?.id;
            const searchPackageDocumentId = searchPackageDocuments.find(
                (doc) => doc.id === documentToSearchFor
            )?.files[documentFileIndex]?.id;
            const startersAndPriorsDocumentId = startersAndPriorsDocuments.find(
                (doc) => doc.id === documentToSearchFor
            )?.files[documentFileIndex]?.id;
            const taxDocumentId = taxDocuments.find((doc) => doc.id === documentToSearchFor)
                ?.files[currentDocFileIndex]?.id;
            const fileId =
                keyDocumentId ||
                searchPackageDocumentId ||
                startersAndPriorsDocumentId ||
                taxDocumentId;
            if (!fileId) return;
            filesToMerge.push({ documentId: detail.documentId, fileId });
        }
    });

    if (filesToMerge.length && (orderId || examOrderId)) {
        try {
            const { buffer, type } = await getMergedExamDocumentFile(
                orderId || examOrderId,
                filesToMerge
            );
            return { filename, buffer, type };
        } catch (err) {
            handleError(err);
        }
    }
};

export default mergeDownloadImage;
