import { handleError } from 'core/helpers/errorHandler';
import { UserProfileData, UserProfileWriteDto, ProfileImageReadDto } from 'types/userProfile';
import api from '../api';

/**
 * Fetches user profile by object ID.
 * @param {string} oid - Object ID of the user.
 * @returns {Promise<UserProfileData>} - User profile data.
 */
export const getUserByObjectId = async (oid: string): Promise<UserProfileData> => {
    try {
        const response = await api.get<UserProfileData>(`/api/user/getByObjectId/${oid}`);
        return response.data;
    } catch (e) {
        handleError(e);
        return undefined;
    }
};

/**
 * Creates a profile for the logged-in user.
 * @returns {Promise<UserProfileData>} - Created user profile data.
 */
export const createLoggedInUser = async (): Promise<UserProfileData> => {
    try {
        const response = await api.post<UserProfileWriteDto, UserProfileData>(
            `/api/user/createLoggedInUser`
        );
        return response.data;
    } catch (e) {
        handleError(e);
        return undefined;
    }
};

/**
 * Create and update user profile, This is related to manager dashboard
 * @function
 * @category API
 * @returns {UserProfileData}
 */
export const updateLoggedInUser = async (): Promise<UserProfileData> => {
    try {
        const response = await api.post<null, UserProfileData>(
            `/api/user/createAndUpdateLoggedInUser`
        );
        return response.data;
    } catch (e) {
        return;
    }
};

/**
 * Retrieves user profile image as a blob URL.
 * @param {string} userId - User ID.
 * @returns {Promise<string>} - Blob URL of the image.
 */
export const getUserProfileImage = async (userId: string): Promise<string> => {
    try {
        const response = await api.get<Blob>(
            `/api/UserProfilePhoto/${userId}/getUserProfileImage`,
            { responseType: 'blob' }
        );
        return URL.createObjectURL(new Blob([response.data], { type: 'image/png' }));
    } catch (e) {
        return undefined;
    }
};

/**
 * Uploads a new user profile image.
 * @param {string} userId - User ID.
 * @param {FormData} formData - Form data containing the image file.
 * @returns {Promise<ProfileImageReadDto>} - Response data of the uploaded image.
 */
export const uploadUserProfileImage = async (
    userId: string,
    formData: FormData
): Promise<ProfileImageReadDto> => {
    try {
        const response = await api.post(`/api/UserProfilePhoto/${userId}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (e) {
        handleError(e);
        return undefined;
    }
};

/**
 * Deletes the user profile image.
 * @param {string} userId - User ID.
 * @returns {Promise<void>} - Resolves on success.
 */
export const deleteUserProfileImage = async (userId: string): Promise<string> => {
    try {
        await api.delete(`/api/UserProfilePhoto/${userId}/delete`);
    } catch (e) {
        handleError(e);
        return undefined;
    }
};

/**
 * Updates user profile image.
 * @param {string} userId - User ID.
 * @param {FormData} formData - Form data containing the image file.
 * @returns {Promise<ProfileImageReadDto>} - Response data of the updated image.
 */
export const updateUserProfileImage = async (
    userId: string,
    formData: FormData
): Promise<ProfileImageReadDto> => {
    try {
        const response = await api.put(`/api/UserProfilePhoto/${userId}/update`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (e) {
        handleError(e);
        return undefined;
    }
};
