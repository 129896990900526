import { getExamDocumentFile } from 'core/api/examBlobDocumentFile';
import { CommonDocument, KeyDocument } from 'types/dataModels';
import { handleError } from 'core/helpers/errorHandler';
import api from 'core/api';
import { flattenDocuments } from 'core/helpers/flattenDocs';
import store, { RootState } from '../store/store';

const downloadImage = async (orderId?: string, documentId?: string, fileId?: string) => {
    // Get the state of the store
    const state: RootState = store.getState();

    const examOrderId = state.currentExamOrderData?.currentExamOrder?.id;
    if (!examOrderId && !orderId) return;
    const { currentDocSelectValue, currentDocFileIndex } = state.documentViewerData;

    const searchPackageDocuments: CommonDocument[] =
        state.examOrderSearchPackageGroupData.examOrderSearchPackageGroup.flatMap(
            (group) => group.documents
        );
    const startersAndPriorsDocuments: CommonDocument[] =
        state.examOrderStartersAndPriorsGroupData.examOrderStartersAndPriorsGroup.flatMap(
            (group) => group.documents
        );
    const taxDocuments = state.examOrderTaxesData.examOrderTaxes;
    const keyDocuments: KeyDocument[] = flattenDocuments(
        state.examOrderKeyDocumentGroupData.examOrderDocumentGroup
    );
    const searchResultDocuments = state.runsheetData?.runsheetDetails?.searchResultDocuments;
    const documentToSearchFor = documentId ?? currentDocSelectValue;
    const documentFileIndex = documentId ? '0' : currentDocFileIndex;

    const kdDocument = keyDocuments.find((doc) => doc.id === documentToSearchFor);
    const spDocument = searchPackageDocuments.find((doc) => doc.id === documentToSearchFor);
    const stprDocument = startersAndPriorsDocuments.find(
        (doc) => doc.id === documentToSearchFor
    );
    const taxDocument = taxDocuments.find((doc) => doc.id === documentToSearchFor);
    const runsheetDocument = searchResultDocuments?.find(
        (doc) => doc.id === documentToSearchFor
    );

    let isOcr: boolean;
    let filename: string;
    if (kdDocument) {
        filename = kdDocument?.files[currentDocFileIndex]?.fileName;
        isOcr = kdDocument.isOcr;
    } else if (spDocument) {
        filename = spDocument?.files[currentDocFileIndex]?.fileName;
        isOcr = spDocument.isOcr;
    } else if (stprDocument) {
        filename = stprDocument?.files[currentDocFileIndex]?.fileName;
        isOcr = stprDocument.isOcr;
    } else if (taxDocument) {
        filename = taxDocument?.files[currentDocFileIndex]?.fileName;
        isOcr = taxDocument.isOcr;
    } else if (runsheetDocument) {
        filename = 'runsheet';
        isOcr = false;
    } else {
        return;
    }

    if (documentId && fileId) {
        const { buffer, type } = await getExamDocumentFile(examOrderId, documentId, fileId);
        return { filename, buffer, type };
    } else {
        try {
            const keyDocumentId = keyDocuments.find((doc) => doc.id === documentToSearchFor)
                ?.files[documentFileIndex]?.id;
            const searchPackageDocumentId = searchPackageDocuments.find(
                (doc) => doc.id === documentToSearchFor
            )?.files[documentFileIndex]?.id;
            const startersAndPriorsDocumentId = startersAndPriorsDocuments.find(
                (doc) => doc.id === documentToSearchFor
            )?.files[documentFileIndex]?.id;
            const taxDocumentId = taxDocuments.find((doc) => doc.id === documentToSearchFor)
                ?.files[currentDocFileIndex]?.id;

            const fileId =
                keyDocumentId ||
                searchPackageDocumentId ||
                startersAndPriorsDocumentId ||
                taxDocumentId;

            // runsheet
            if (!fileId && spDocument) {
                const {
                    isAtsFullSearch,
                    pulseSearchId,
                    dataSourceSearchId,
                    searchRequestId,
                    dataProviderActorId,
                    propertyId
                } = spDocument;
                const { id: orderId } = state.currentExamOrderData.currentExamOrder;
                const { buffer, type } = await api.runsheet.getPdfRunsheet({
                    isAtsFullSearch,
                    orderId,
                    pulseSearchId,
                    dataSourceSearchId,
                    searchRequestId,
                    dataProviderActorId,
                    propertyId
                });
                return { fileName: 'runsheet', buffer, type, isOcr };
            }

            if (runsheetDocument) {
                const {
                    isAtsFullSearch,
                    pulseSearchId,
                    dataSourceSearchId,
                    searchRequestId,
                    dataProviderActorId,
                    propertyId
                } =
                    state.examOrderKeyDocumentGroupData.examOrderDocumentGroup.find(
                        (doc) =>
                            doc.groupId === state.runsheetData.runsheetDetails.keyDocumentsId
                    ) ||
                    searchPackageDocuments.find(
                        (doc) => doc.id === state.runsheetData.runsheetDetails.searchPackageId
                    );
                const { id: orderId } = state.currentExamOrderData.currentExamOrder;
                const documentData = {
                    id: runsheetDocument.id,
                    searchResultId: runsheetDocument.searchResultId,
                    searchResultDocId: runsheetDocument.searchResultDocId,
                    dataProviderUniqueDocId: runsheetDocument.dataProviderUniqueDocId,
                    viewImageActionUrl: runsheetDocument.actions?.find(
                        (action) => action.Type === 'VIEW_IMAGE'
                    )?.URL,
                    isFinalDocument: runsheetDocument.isFinalDocument,
                    isTagged: runsheetDocument.isTagged,
                    isSaved: runsheetDocument.isSaved
                };
                const res = await api.runsheet.getImage({
                    isAtsFullSearch,
                    orderId,
                    pulseSearchId,
                    dataSourceSearchId,
                    searchRequestId,
                    dataProviderActorId,
                    propertyId,
                    document: documentData
                });
                document.dispatchEvent(new CustomEvent('updateKeyDocumentsGroupingList'));
                return {
                    filename: 'runsheet',
                    buffer: res.buffer,
                    type: res.type,
                    runsheetDocument: { ...runsheetDocument, isSaved: true }
                };
            }

            if (!fileId) return;

            const { buffer, type } = await getExamDocumentFile(
                examOrderId,
                documentToSearchFor,
                keyDocumentId ||
                    searchPackageDocumentId ||
                    startersAndPriorsDocumentId ||
                    taxDocumentId
            );
            return { filename, buffer, type, isOcr };
        } catch (err) {
            handleError(err);
        }
    }
};

export default downloadImage;
