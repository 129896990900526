import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import api from 'core/api';
import { AppThunk } from 'core/store/store';
import { setSnackbarState } from 'core/features/snackbar/snackbarSlice';
import {
    GlobalOrderFilter,
    GlobalStatusFilter,
    SnackbarSeverity,
    OrderStatusesIds
} from 'core/constants/common';
import { PagedData, ProjectOrder } from 'types/dataModels';

export interface OrdersData {
    orders: {
        isFiltersShown: boolean;
        globalStatusFilter: GlobalStatusFilter;
        globalOrderFilter: GlobalOrderFilter;
        globalRushFilter: boolean;
        allOrders: PagedData<ProjectOrder>;
        inProgressOrders: PagedData<ProjectOrder>;
    };
}

const initialState: OrdersData = {
    orders: {
        isFiltersShown: false,
        globalStatusFilter: GlobalStatusFilter.CompletedExcluded,
        globalOrderFilter: GlobalOrderFilter.AllOrders,
        globalRushFilter: false,
        allOrders: {
            data: [],
            page: 1,
            pageSize: 20,
            count: 0,
            totalPages: 0,
            totalCount: 0,
            pageLimit: 100
        },
        inProgressOrders: {
            data: [],
            page: 1,
            pageSize: 20,
            count: 0,
            totalPages: 0,
            totalCount: 0,
            pageLimit: 100
        }
    }
};

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        setOrders(state, action: PayloadAction<PagedData<ProjectOrder>>) {
            state.orders.allOrders = action.payload;
        },
        setInProgressOrders(state, action: PayloadAction<PagedData<ProjectOrder>>) {
            state.orders.inProgressOrders = action.payload;
        },
        setIsFiltersShown(state, action: PayloadAction<boolean>) {
            state.orders.isFiltersShown = action.payload;
        },
        setGlobalStatusFilter(state, action: PayloadAction<GlobalStatusFilter>) {
            state.orders.globalStatusFilter = action.payload;
        },
        setGlobalOrderAssignedFilter(state, action: PayloadAction<GlobalOrderFilter>) {
            state.orders.globalOrderFilter = action.payload;
        },
        setGlobalRushFilter(state, action: PayloadAction<boolean>) {
            state.orders.globalRushFilter = action.payload;
        },
        /**
         * Updates the order project status
         * @param state Slice state
         * @param action Payload with updated order data
         */
        updateOrderProjectStatus(state, action: PayloadAction<ProjectOrder>) {
            const order = state.orders.allOrders.data.find(
                (order) => order.id === action.payload.projectId
            );
            order.projectStatus = action.payload.projectStatus;
            order.projectStatusId = action.payload.projectStatusId;
        }
    }
});

export const {
    setOrders,
    setInProgressOrders,
    setIsFiltersShown,
    setGlobalStatusFilter,
    setGlobalOrderAssignedFilter,
    setGlobalRushFilter,
    updateOrderProjectStatus
} = ordersSlice.actions;

/**
 * Fetch paged list of orders from BE
 * @param {number} pageNumber Number of the page to fetch orders from
 * @param {number} pageSize Number of the page to fetch orders from
 * @param {number[]} stateIds IDs of the states to filter orders by
 * @param {number[]} countyIds IDs of the counties to filter orders by
 * @param {number[]} businessSegmentIds IDs of the business segments to filter orders by
 * @param {number[]} actionsIds IDs of the actions to filter orders by
 * @param {number[]} productTypeIds IDs of the product types to filter orders by
 * @param {string[]} clientIds IDs of the clients to filter orders by
 * @param {string[]} statusIds IDs of the statuses to filter orders by
 * @param {string} orderFilter Order filter
 * @param {string} search String that represents the user search keywords to filter orders by
 * @param {object} sorting Object that describes the sorting of the order list
 * @param {string} fromDate ISO date string to filter from date
 * @param {string} toDate ISO date string to filter to date
 * @param {boolean} isRush is rush order
 * @returns {AppThunk}
 */
export const fetchPagedOrders =
    (
        pageNumber: number,
        pageSize: number,
        stateIds?: number[],
        countyIds?: number[],
        businessSegmentIds?: number[],
        actionsIds?: number[],
        productTypeIds?: number[],
        clientIds?: string[],
        statusIds?: string[],
        orderFilter?: string,
        search?: string,
        sorting?: {
            fieldSorting: { name: string; direction: 0 | 1 }[];
        },
        fromDate?: string,
        toDate?: string,
        isRush?: boolean
    ): AppThunk =>
    async (dispatch) => {
        try {
            const response = await api.orders.getPagedOrdersDataApi({
                page: pageNumber,
                pageSize,
                stateIds,
                countyIds,
                businessSegmentIds,
                actionsIds,
                productTypeIds,
                clientIds,
                statusIds,
                orderFilter,
                search,
                sorting,
                fromDate,
                toDate,
                isRush
            });
            dispatch(setOrders(response));
        } catch (err) {
            dispatch(setOrders(initialState.orders.allOrders));
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Get project orders: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Start the exam of an existing order
 * @param {string} orderId ID of the order to start exam
 * @returns {AppThunk}
 */
export const startExamThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            await api.orders.startExam(orderId);
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Start exam order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

/**
 * Fetch in progress orders
 * @returns {AppThunk}
 */
export const fetchInProgressOrders = (): AppThunk => async (dispatch, getState) => {
    try {
        const { profileData } = getState();
        const userId = profileData.profile.id;
        const inProgressStatusId = OrderStatusesIds.InProgress;
        if (inProgressStatusId && userId) {
            const response = await api.orders.getInProgressOrders(inProgressStatusId, userId);
            dispatch(setInProgressOrders(response));
        }
    } catch (err) {
        dispatch(
            setSnackbarState({
                open: true,
                message: `Get in progress orders: ${err.message}`,
                severity: SnackbarSeverity.Error
            })
        );
    }
};

/**
 * re-send order to mark order status as Complete
 * @param {string} orderId ID of the order
 * @returns {AppThunk}
 */
export const resendOrderThunk =
    (orderId: string): AppThunk =>
    async (dispatch) => {
        try {
            const result = await api.examOrder.completeOrder(orderId);
            dispatch(updateOrderProjectStatus(result));
        } catch (err) {
            dispatch(
                setSnackbarState({
                    open: true,
                    message: `Re-send order: ${err.message}`,
                    severity: SnackbarSeverity.Error
                })
            );
        }
    };

export default ordersSlice.reducer;
