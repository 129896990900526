import { Box, Grid } from '@mui/material';
import Header from 'components/common/AppHeader';
import LeftSidebar from 'components/common/LeftSidebar';
import { textStylesMontserrat } from 'styles/common';
import { useEffect } from 'react';
import { useAppDispatch } from 'core/hooks/useAppDispatch';
import { fetchProfileData } from 'core/features/profile/profileSlice';
import styles from './styles';

/**
 * Render unauthorized page
 * @component
 * @returns {JSX.Element}
 */
const UnAuthorized = () => {
    const dispatch = useAppDispatch();

    const { root } = styles().classes;
    const { subtitle_16_semibold } = textStylesMontserrat().classes;

    /**
     * initial data loading
     */
    useEffect(() => {
        async function init() {
            dispatch(fetchProfileData());
        }
        init();
    }, []);

    return (
        <div className="content">
            <Header />
            <LeftSidebar />
            <section className="mainContainer">
                <Grid className={root} display={'flex'} flexDirection={'column'}>
                    <Box className={subtitle_16_semibold} color={'#000000'}>
                        You don&apos;t have permission to view this page.
                    </Box>
                </Grid>
            </section>
        </div>
    );
};

export default UnAuthorized;
